import React, { useEffect, useState } from 'react'
import { sleep, validatePhoneNumber } from '../helpers';
import ReactLoading from 'react-loading';
import { signInWithGoogle, updateUser } from '../firebaseConfig';

const TopUpCard = ({ user_data, initial_amount, callback }) => {

    const [prompting, setPrompting] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [msisdn, setmsisdn] = useState(user_data.phone)
    const [amount, setAmount] = useState(initial_amount ?? 0)


    const handleMSISDNChange = (event) => {
        const phoneNumber = event.target.value
        const result = validatePhoneNumber(phoneNumber);
        if (result.result !== "invalid") {
            setmsisdn(result.result);
        } else {
            setmsisdn('')
        }
    }


    const SendPayload = async (payload, { setPrompting, setWaiting }) => {

        // console.log(payload)

        if (payload.amount === '' || payload.msisdn === '') {
            alert("Missing info")
        } else {
            setPrompting(true);
            setWaiting(true);
            const url = `https://us-central1-firenode-86abd.cloudfunctions.net/app/gateway`;
            const body = `amount=${payload.amount}&msisdn=${payload.msisdn}&account_id=subscription/${payload.account_id}/${0}`;
            fetch(url, {
                body: body,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "POST",
            })
                .then((response) => response.json())
                .then(async (data) => {
                    // console.log(data)

                    if (data.success) {
                        console.log('ok')
                        await updateUser(payload.account_id, { phone: payload.msisdn })
                    } else {

                    };
                    await sleep(12000)
                    setWaiting(false)
                    setPrompting(false)
                    callback?.()

                })
                .catch((error) => {
                    console.error('Error:', error)
                    setPrompting(false)
                    setWaiting(false)
                    alert(error)
                });
        }
    }

    useEffect(() => {
        console.log('inital amount', initial_amount ?? 0)
        setAmount(initial_amount ?? 0)
    }, [initial_amount])

    return (
        <div>

            <div className='flex flex-col items-center justify-center px-8 space-y-4'>
                <span className='text-sm font-semibold'>Top up Amount</span>
                <input placeholder='Enter Top up Amount'
                    type='number'
                    onChange={(e) => {

                        const input = e.target.value !== '' ? parseInt(e.target.value) : 0
                        setAmount(input)

                    }}
                    value={amount}
                    name="amount"
                    className={`p-4 text-center relative w-full cursor-default overflow-hidden rounded-lg bg-white  shadow-md ring-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ${amount === 0 ? 'ring-red-100 focus-visible:ring-offset-red-300' : 'ring-teal-100 focus-visible:ring-offset-teal-300'} sm:text-sm`}
                />
                {user_data ?
                    <>

                        <input placeholder='Mpesa Number'
                            type='tel'
                            onChange={(e) => handleMSISDNChange(e)}
                            name="msisdn"
                            defaultValue={`${msisdn}`}
                            className={`p-4 text-center font-semibold relative w-full cursor-default overflow-hidden rounded-lg bg-white  shadow-md ring-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ${msisdn === '' ? 'ring-red-100 focus-visible:ring-offset-red-300' : 'ring-teal-100 focus-visible:ring-offset-teal-300'} sm:text-sm`}
                        />

                        <div className="flex items-center justify-around">
                            {(prompting) ?
                                (waiting) ?
                                    <ReactLoading className="loading-anim" type="bubbles" color="green" />
                                    :
                                    <div className="sm:m-4 flex flex-col justify-center items-center">
                                        <span>{`welcome 🙂`}</span>
                                    </div>
                                :
                                <>
                                    <button
                                        disabled={msisdn === ''}
                                        defaultValue={msisdn}
                                        className="inline-flex my-4 justify-center rounded-md border border-transparent disabled:text-gray-900 bg-green-200 px-4 py-2 text-sm font-medium disabled:bg-gray-300 text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                        onClick={() => {
                                            SendPayload({
                                                amount: amount,
                                                msisdn: msisdn,
                                                account_id: user_data?.uid
                                            }, { setPrompting, setWaiting })
                                        }}
                                    >Prompt</button>
                                </>
                            }
                        </div>
                    </>
                    :
                    <button
                        className="inline-flex m-6 justify-center rounded-md border border-transparent bg-blue-200 px-4 py-2 text-sm font-medium disabled:bg-gray-300 text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                        onClick={() => { signInWithGoogle() }}
                    >{'Sign In to continue'}</button>
                }
            </div>
        </div>
    )
}

export default TopUpCard