import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Menu from './pages/Menu';
import Landing from './pages/Landing';
import Header from './components/ui/header';
import { Fragment, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, signInWithGoogle } from './firebaseConfig';
import Request from './pages/Request';
import Account from './pages/Account';
import { Helmet } from 'react-helmet';
import Preloved from './pages/Preloved';
import { addMenu, getMenus } from './firebaseConfig'
import { useNavigate } from 'react-router-dom'
import { distances, validatePhoneNumber } from './helpers'
import { Dialog, Switch, Transition } from '@headlessui/react'
import ReactLoading from 'react-loading';
import Accounts from './pages/Accounts';
import WhatsappEverClear from './pages/WhatsappEverClear';


function App() {

  const prod = true;
  const [title, setTitle] = useState('APPLYMYCV')
  const [naved, setNav] = useState(false)

  const [user, user_loading, user_error] = useAuthState(auth);

  const handleTitle = (title) => {
    setTitle(title)
  }

  const [enabled, setEnabled] = useState(false)
  const [likes, setLikes] = useState([])
  const [pos, setPos] = useState(null)
  const [limit, setLimit] = useState(distances[0].max)
  const [items, setItems] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [busuness, setBusiness] = useState('')
  const [desc, setDesc] = useState('')
  const [phone, setPhone] = useState('')
  const [file, setFile] = useState("")
  const [percent, setPercent] = useState(0)

  const navigateToAccount = () => {
    // 👇️ navigate
    setIsOpen(false)
    setUploading(false)
    window.location.href = '/account'
  }
  const openModal = () => {
    setIsOpen(true)
  }
  const closeModal = () => {
    setIsOpen(false)
  }
  const handleNameChange = (event) => {
    setBusiness(event.target.value);
  }
  const handleFileChange = (event) => {
    setPercent(0)
    setFile(event.target.files[0]);
  }
  const handlePhoneChange = (event) => {
    const phoneNumber = event.target.value
    const result = validatePhoneNumber(phoneNumber);
    // console.log(result, phoneNumber)
    if (result.result !== "invalid") {
      setPhone(result.result);
    } else {
      setPhone('')
    }
  }
  const handleUpload = async () => {
    if (pos) {
      setUploading(true)
      const data = {
        ...pos,
        title: busuness,
        desc: desc,
        requests: 0,
        likes: 0,
        uid: user.uid,
        image: '',
        balance: 0,
        sales: 0,
        bonus: enabled ? 500 : 0,
        limit: parseInt(limit),
        active: true,
        phone: phone,
        prompt: enabled
      }

      if (file) {
        await addMenu(file, setPercent, data, navigateToAccount)
      } else {
        setUploading(false)
        console.log('no file')
      }
    } else {
      console.log('no pos')
    }
  }
  const handleFetch = (pos) => {
    getMenus(pos, setItems)
  }
  const handleLocation = async (open) => {
    //console.log(pos)
    if (navigator.geolocation) {
      open && openModal()
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: parseFloat(position.coords.latitude),
            lng: parseFloat(position.coords.longitude),
          }

          setPos(pos)
          handleFetch(pos)

        },
        (e) => {
          console.log("Browser doesn't support Geolocation", e)
          // handleLocationError(true, infoWindow, map.getCenter());
        }
      );
    } else {
      console.log("Browser doesn't support Geolocation")
      // Browser doesn't support Geolocation
      // handleLocationError(false, infoWindow, map.getCenter());
    }
  }

  return (
    <div className=''>
      <Helmet>
        <title>ApplymyCV</title>
        <meta property="og:title" content="ApplymyCV" />
        <meta property="og:description" content='Enable location shopping' />
        <meta name="description" content="Visit duaknear.com to take advantage of amazing resale prices!" />
        <link rel="canonical" href={`http://applymycv.com`} />
      </Helmet>
      <Router>
        <Header title={title} user={user} naved={naved} handleLocation={handleLocation} />
        <Routes>
          {/* <Route path="/" element={<Landing prod={prod} user={user} />} /> */}
          <Route path="/" element={<WhatsappEverClear prod={prod} user={user} />} />
          <Route path="/:id" element={<WhatsappEverClear prod={prod} user={user} />} />
          <Route path="/account" element={<Accounts prod={prod} handleTitle={handleTitle} editor={true} user={user} handleLocation={handleLocation} />} />
          <Route path="/account/:id" element={<Account prod={prod} handleTitle={handleTitle} editor={true} user={user} handleLocation={handleLocation} />} />
          <Route path="/request/:id" element={<Request prod={prod} user={user} />} />
          <Route path="/preloved" element={<Preloved prod={prod} user={user} />} />
          <Route path="/preloved/:prelovedid" element={<Landing prod={prod} user={user} />} />
          {/* <Route path="/:id" element={<Landing prod={prod} user={user} />} /> */}
          <Route path="" element={<WhatsappEverClear prod={prod} user={user} />} />
          <Route path="/:id" element={<WhatsappEverClear prod={prod} user={user} />} />
          <Route path="/whatsapp-ever-clear" element={<WhatsappEverClear prod={prod} user={user} />} />
        </Routes>
      </Router>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >{
                  pos ? <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Welcome to ApplymyCV!
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className='flex flex-col space-y-4'>
                        {/* <label for="prompt" className='text-sm hidden'><b>Note:</b> Karibu Bonus applies to <u>Prompt to Pay Vendors payable at KES 5500 sales balance</u></label> */}
                        {/* <Switch.Group name='prompt'>
                          <div className="flex items-center">
                            <Switch.Label className="mr-4">Enable Prompt to Pay? </Switch.Label>
                            <Switch
                              checked={enabled}
                              onChange={setEnabled}
                              className={`${enabled ? 'bg-blue-600' : 'bg-gray-200'
                                } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                            >
                              <span
                                className={`${enabled ? 'translate-x-6' : 'translate-x-1'
                                  } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                              />
                            </Switch>
                          </div>
                        </Switch.Group> */}
                        <input placeholder='Business Name (client facing)' type='text' onChange={(e) => handleNameChange(e)}
                          className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />
                        <input placeholder='Whatsapp Number (for orders)' type='number' onChange={(e) => handlePhoneChange(e)}
                          className={`p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ${phone === '' ? 'focus-visible:ring-offset-red-300' : 'focus-visible:ring-offset-teal-300'} sm:text-sm`} />
                        <label for="limit">How far will you serve client's at <b>no additional cost?</b></label>
                        <div className='flex flex-row items-center justify-center px-4 flex-wrap'>
                          {distances.map((p) => <button
                            className={`inline-flex justify-center rounded-md border border-transparent ${p.max === limit ? 'bg-green-500 text-white' : 'bg-green-100 text-green-900'} px-6 py-4 text-sm font-medium  focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 m-1`}
                            onClick={() => setLimit(p.max)}
                          >
                            {`${p.max} ${p.key}`}
                          </button>)}
                        </div>
                        <label for="avatar">Upload a photo of yourself / store:</label>
                        <input className='bg-white' type="file" accept="image/*" id="avatar" name="avatar"
                          onChange={handleFileChange} />
                      </div>
                    </div>

                    <div className="mt-4">
                      {uploading ?
                        <ReactLoading type="bubbles" color="black" />
                        : <button
                          disabled={
                            title === ''
                            || phone === ''
                            || !pos
                            || !file
                          }
                          type="button"
                          className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                          onClick={handleUpload}
                        >
                          Create Store
                        </button>}
                    </div>
                  </Dialog.Panel>
                    :
                    <div className='flex flex-row flex-wrap items-center justify-center pt-[10vh]'>
                      <ReactLoading type="bubbles" color="black" />
                    </div>
                }
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default App;
