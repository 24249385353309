import React, { Fragment, useEffect, useState } from 'react'
import Menu from './Menu'
import CircularProgressWithValue from '../components/CircularProgressWithValue'
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, getUserMenus } from '../firebaseConfig';
import AddMenuItem from '../components/AddMenuItem';
import { useNavigate } from 'react-router-dom';

const Accounts = ({ prod, handleTitle, editor, user, menuid, search, handleLocation }) => {
    const navigate = useNavigate();


    const [curr_user, user_loading, user_error] = useAuthState(auth)
    const [items, setItems] = useState(null)

    const handleClick = (id) => {
        navigate(`/account/${id}`)
    }


    useEffect(() => {
        user && getUserMenus(user.uid, setItems)
    }, [user])

    return (

        <section className="h-full w-full relative">
            <div className={`py-20 flex flex-col items-center h-full`}>
                <button className='w-2/3 flex md:max-w-[50vw]  mx-8  my-4 justify-center space-x-1 items-center rounded-lg border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                    onClick={handleLocation}>
                    <span>Add Duka</span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </button>
                <div className='flex flex-col space-y-4'>
                    {
                        items?.map((item) => {
                            return (
                                <button
                                    onClick={() => handleClick(item.id)}
                                    className='flex flex-row justify-start items-center bg-white py-2 rounded-xl px-4 shadow hover:shadow-lg space-x-4'>
                                    <img alt='' src={item.image} className={`w-16 h-16 md:w-16 md:h-16 object-cover object-center rounded-full bg-slate-500`} loading='lazy' />
                                    <span className='font-light text-xl text-center'>{`${item.title} (${item.active ? 'Listed' : 'Unlisted'})`}</span>
                                </button>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default Accounts