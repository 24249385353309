import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, Switch, Transition } from '@headlessui/react'
import { shareOnMobile } from 'react-mobile-share';
import { Alert, Collapse } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { validatePhoneNumber } from '../helpers';
import { db, COLLECTIONS } from '../firebaseConfig'
import { useAuthState, useCollection, useDocument, useCollectionData } from 'react-firebase-hooks/firestore';
import { doc, collection, query, where, orderBy, limit, getFirestore, setDoc, getDoc, getDocs, updateDoc, Timestamp, increment } from 'firebase/firestore';

import { Button } from '@mui/material'

const AddMenuItem = ({ mid, editor, user, handleNew }) => {

    const [share, setShare] = useState(false)
    const [copied, setCopy] = useState(false)

    const handleShareClose = () => {
        setShare(false);
        setCopy(false);
    };
    const handleShareCopy = () => {
        setCopy(true);
    };

    return (
        <div className='text-white flex flex-row px-4 py-2 items-center  justify-center'>
            {editor &&
                <button className='w-2/3 flex md:max-w-[50vw]  mx-8  my-1 justify-center space-x-1 items-center rounded-lg border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                    onClick={handleNew}>
                    <span>New</span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </button>}

            <button className='w-2/3 flex md:max-w-[50vw] mx-8 my-1 justify-center space-x-1 items-center rounded-lg border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                onClick={() =>
                    shareOnMobile(
                        {
                            text: '',
                            url: `https://applymycv.com/${mid}`,
                            title: '',
                        },
                        () => {
                            setShare(true);
                        }
                    )
                    }>
                <span>Share</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-4">
                    <path fillRule="evenodd" d="M15.75 4.5a3 3 0 11.825 2.066l-8.421 4.679a3.002 3.002 0 010 1.51l8.421 4.679a3 3 0 11-.729 1.31l-8.421-4.678a3 3 0 110-4.132l8.421-4.679a3 3 0 01-.096-.755z" clipRule="evenodd" />
                </svg>

            </button>


            <Transition appear show={share} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={handleShareClose}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        Share your offer with clients:
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <div className='flex flex-col space-y-4'>
                                            <span style={{ fontSize: '12px', fontWeight: '700' }}>
                                                {`https://applymycv.com/${mid}`}
                                            </span>

                                            <Collapse in={copied}>
                                                <Alert severity="success" sx={{ mb: 2 }}>
                                                    Link copied!
                                                </Alert>
                                            </Collapse>
                                        </div>
                                    </div>

                                    <div className="flex items-center mt-4">
                                        <button onClick={handleShareClose}
                                            className="font-medium text-gray-600 hover:text-red-400 px-5 py-3 flex items-center transition duration-150 ease-in-out">
                                            Dismiss
                                        </button>
                                        <CopyToClipboard
                                            text={`https://applymycv.com/${mid}`}
                                            onCopy={handleShareCopy}>
                                            <button
                                                className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                            >Copy
                                            </button>
                                        </CopyToClipboard>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>

        </div>
    )
}

export default AddMenuItem