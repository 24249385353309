import { Alert, Collapse, Dialog, DialogContent, DialogContentText, DialogTitle, ImageList, ImageListItem, LinearProgress } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { shorten, shortenUrl, toTitleCase } from '../helpers'
import { prelovedUpdate } from '../firebaseConfig'
import CopyToClipboard from 'react-copy-to-clipboard'
import ReactLoading from 'react-loading';
import Logo from '../assets/logo.png'

const PreLovedListing = ({ item, handleSave, saved, topped, foruser, handleMyListingClick }) => {

  const thumb = item.thumb
  const id = item.id
  const caption = item.caption
  const code = item.code
  const version = item.version
  const active = item.active
  const saves = item.saves ?? 0
  const date = moment(item.posted).fromNow()
  const posted = date.includes('hour') ? 'today' : date === 'a day ago' ? 'yesterday' : date

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [isSaved, SetIsSaved] = useState(null)
  const [intervalId, setintervalId] = useState(null);
  const [copied, setCopy] = useState(false)
  const [copying, setCopying] = useState(false)
  const [socialLink, setSocialLink] = useState('')


  const getShareLink = async () => {
    setCopying(true)
    const res = await shortenUrl(`https://us-central1-autosoft-614e7.cloudfunctions.net/socialShare/preloved/${id}`, caption)
    console.log(res)
    setSocialLink(res)
  }
  const handleShareCopy = () => {
    setCopy(true);
  }
  const handleOpen = () => {

    if (foruser && !active) {
      handleMyListingClick(item)
    } else {
      setOpen(true)
    }

  }
  const handleClose = () => {
    setOpen(false)
    setCopy(false)
  }

  const handleClick = async () => {
    console.log(caption)
    try {
      const call = await fetch('https://instagpt-jqj6v4kycq-uc.a.run.app', {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json',
          'Access-Control-Request-Headers': 'Content-Type, Accept, Access-Control-Allow-Origin, Access-Control-Max-Age, Access-Control-Allow-Methods',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ caption: caption })
      })
      const response = await call.json()
      console.log('goodies', response)

    } catch (err) {
      console.log(err);
    }
  }

  const goToNext = () => {
    if (thumb.indexOf(thumb[currentImage]) === currentImage) {

      const isLastSlide = currentImage === thumb.length - 1;
      const newIndex = isLastSlide ? 0 : currentImage + 1;
      setCurrentImage(newIndex);
      console.log('in', newIndex)
    }
  };

  const handleDelist = () => {
    prelovedUpdate(item.id, { active: false })

  }


  const startTimer = () => {
    const id = setInterval(goToNext, 2000)
    setintervalId(id)
  }

  const stopTimer = () => {
    console.log('out', intervalId)
    clearInterval(intervalId);
    setintervalId(null)
  }

  const Like = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
        <path strokeLinecap="round" strokeLinejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" />
      </svg>

    )
  }

  const Liked = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
        <path fillRule="evenodd" d="M6.32 2.577a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 01-1.085.67L12 18.089l-7.165 3.583A.75.75 0 013.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93z" clipRule="evenodd" />
      </svg>
    )
  }


  return (
    <div className={`${topped ? ' w-1/3 md:w-48' : ' w-1/2 md:w-72'} px-1`}>
      <div className={` border border-white hover:border-stone-100 inline-flex flex-col justify-start mb-2 flex-shrink-0 hover:shadow-xl py-4 ${foruser ? 'bg-gray-300 py-2 rounded px-2 mx-1' : ' rounded-md px-2'}`}>
        {foruser &&
          <div className='flex justify-between py-2'>
            <div className='flex justify-center space-x-4 items-center  '>
              <span>{active ? '🟢' : '⚫️'}</span>
              <span className={`${'text-sm md:text-base'} font-semibold`}>{`${active ? 'Live' : 'Inactive'}`}</span>
            </div>
            {<button
              disabled={!active}
              className='bg-white hover:bg-red-600 hover:text-white rounded-lg text-xs px-4 py-2 disabled:bg-gray-500 disabled:text-white'
              onClick={() => {
                handleDelist()
              }}>
              Delist
            </button>}
          </div>
        }
        <div className='w-full h-full relative mb-2'>
          <img alt='logo' src={Logo} className='hidden absolute bottom-0 right-0 object-cover h-8 w-8 p-1 mx-2 my-1 bg-white rounded-lg bg-opacity-30' />

            <img className={`cursor-pointer aspect-square rounded-2xl border-b border-red-900 object-cover object-center bg-slate-500`}
              loading='lazy'
              alt={caption}
              src={thumb?.[currentImage]}
              onClick={handleOpen}
            />

        </div>
        <div className={topped ? '' : 'px-4'}>
          {!foruser &&
            <div className='flex justify-between items-center  '>
              <span className={`${topped ? ' text-sm md:text-[10px]' : 'text-[12px] md:text-[14px]'} font-semibold`}>{`${toTitleCase(date)}`}</span>

              {!topped && <button
                onClick={() => {
                  handleSave(code, saves)
                  SetIsSaved(!saved)
                }}>
                {isSaved === null ?
                  saved ?
                    <Liked />
                    :
                    <Like />
                  :
                  isSaved ?
                    <Liked />
                    :
                    <Like />

                }

              </button>}
            </div>
          }

          <span className={`${version === 2 ? active ? '' : ' line-through' : ''} ${topped ? 'text-[8px] line-clamp-1' : 'text-[10px] line-clamp-2'} font-light`}>{caption}</span>

        </div>
      </div>
      <Dialog open={open} onClose={handleClose}>
        {loading && <LinearProgress />}
        <DialogContentText className='p-4 bg-white' sx={{ fontSize: '10px', color: 'black' }}>{caption} </DialogContentText>
        <DialogContent className='bg-red-900 py-8 overflow-auto'>
          <ImageList variant="masonry" sx={{ marginTop: '16px' }} cols={1} gap={8}>
            {thumb?.map((item) => (
              <ImageListItem key={item}>
                <img
                  className='rounded-lg'
                  src={`${item}?w=248&fit=crop&auto=format`}
                  srcSet={`${item}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>


        </DialogContent>

        <div className='p-4 flex justify-around items-center'>
          {item.phone && <a href={`tel:${item.phone}`}
            className="inline-flex justify-center  border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
          >
            Call Seller
          </a>}
          {!copying && socialLink === '' && <button onClick={getShareLink}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
            </svg>
          </button>}
          {copying && socialLink === '' ?
            <ReactLoading className="loading-anim" type="bubbles" color="green" />
            : socialLink !== '' && <CopyToClipboard
              text={socialLink}
              onCopy={handleShareCopy}>
              <button
                className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
              >
                <div className='flex flex-row items-center justify-around'>
                  <span className='mx-1'>Copy Link</span>
                  {<Collapse orientation='vertical' in={copied}>
                    <Alert severity="success" sx={{}}>
                      Link copied!
                    </Alert>
                  </Collapse>}
                </div>
              </button>
            </CopyToClipboard>

          }
        </div>
      </Dialog>
    </div>

  )
}

export default PreLovedListing