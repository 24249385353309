import React from 'react';

const Message = ({ tag, uid, message }) => {
  const msg = message.message
  const sent = message.tag === tag;
  const sys = message.tag === 'system';
  // console.log(message)

  const created = message.created
  const date = created.toDate()
  const shortdate = date.toLocaleDateString("en-GB")
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  const formattedTime = `${hours}:${minutes}:${seconds}`
  const time = `${shortdate} ${formattedTime}`

  
  return (
    // Message container
    <div
      className={`flex justify-stretch items-start rounded-md max-w-[90%] my-1 ${
        sent ? "bg-[#005c4b] ml-auto" : sys? "mr-auto ml-auto" : "bg-[#202d33] mr-auto"
      }`}
    >
      
        <div
          className="px-2 py-1 flex flex-col"
          style={{ wordBreak: "break-word" }}
        >
            <span className={sys?'text-gray-400 text-[10px]':`text-white text-xs`}>{msg}</span>
          
          {!sys &&<span className="text-[#8796a1] text-[6px] min-w-[50px]">{time}</span>}
        </div>
      
    </div>
  );
}

export default Message;
