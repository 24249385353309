import React, { Fragment, useEffect, useState } from 'react'
import { addToOffers, sendMail, signInWithGoogle, template, updateApplicant, updateOfferChat } from '../firebaseConfig'
import { Dialog, Transition } from '@headlessui/react'
import ReactLoading from 'react-loading';
import TopUpCard from './TopUpCard';
import CVUpload from './CVUpload';

const ApplicantCard = ({ applicant, user_data, setSelectedChat, setSelectedTab, handleApplicants }) => {
    const claimed = applicant.claims.includes(user_data?.uid)
    const [uploading, setUploading] = useState(false)
    

    const sendSystemMesssage = async (offer, message) => {
        const data = {
            message: message,
            sender: user_data.uid,
            tag: 'system'
        }
        await updateOfferChat(offer.id, data)
    }
    
    const handleClaim = async () => {

        setUploading(true)
        const data = {
            vendorid: '',
            clientid: applicant.clientid,
            vendoruid: user_data?.uid,
            clientuid: applicant?.uid,
            vendorname: user_data?.name,
            clientname: applicant?.name,
            vendoremail: user_data?.email,
            clientemail: applicant?.email,
            chat: [],
            jobs: applicant.jobs,
            rate: applicant.rate,
            total: applicant.total,
            status: { client: 1, vendor: 1 },
            rating: { value: 0, remark: '' },
            desc: applicant.description,
            vendoraccepted: true
        }

        await addToOffers(data, async (offer) => {
            setSelectedTab(3)
            setSelectedChat(0)
            await updateApplicant(applicant.id, user_data?.uid)
            await sendSystemMesssage(offer, 'Offer Claimed')
            handleApplicants()
            const subject = 'YOUR APPLYMYCV OFFER WAS ACCEPTED'
            const message = template(applicant?.name, `${user_data?.name} has accepted your offer!`)
            await sendMail(applicant?.email, subject, message, user_data?.uid)
            setUploading(false)
        })

    }

    return (
        <div className='w-full rounded flex flex-col border'>
            <div className='w-full border-b flex justify-between px-4 py-2 items-center font-semibold'>
                <span className='flex-1 text-start text-[10px]'>I will pay you {new Intl.NumberFormat().format(applicant.total)}/= for {applicant.jobs} Application{applicant.jobs === 1 ? '' : 's'}</span>
            </div>
            <span className='font-light px-4 py-2'>Expectations: {applicant?.expectations}</span>

            <div className='w-full border-t flex justify-between px-4 py-2 items-center text-sm'>

                <div className="inline-flex justify-center items-center text-sm text-black font-light gap-1">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z" />
                    </svg>
                    <span>{applicant.claims?.length} Claim{applicant.claims?.length === 1 ? '' : 's'}</span>
                </div>
                {
                    user_data ?



                        <button className="inline-flex justify-center items-center rounded-md border disabled:bg-gray-300 disabled:text-gray-900 border-transparent bg-green-100 px-4 py-1 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                            onClick={handleClaim}
                            disabled={claimed || uploading}>
                            {uploading ? <ReactLoading height={'20px'} width={'22px'} type="bubbles" color="white" />
                                : claimed ? 'Claimed' : 'Claim Offer'}
                        </button>
                        :

                        <button
                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-200 px-4 py-2 text-sm font-medium disabled:bg-gray-300 text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                            onClick={() => { signInWithGoogle() }}
                        >{'Sign In to Claim'}</button>
                }

            </div>


        </div>
    )
}

export default ApplicantCard