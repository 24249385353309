import React, { Fragment, useEffect, useRef, useState } from 'react'
import Message from './Message'
import { Dialog, Transition } from '@headlessui/react'
import ReactLoading from 'react-loading';
import { off } from 'firebase/database'
import { commitUserBalance, getCV, remmitUserBalance, sendMail, template, updateOffer, updateOfferChat, updateUserBalances } from '../firebaseConfig';

const OfferCard = ({ tag, offer, user_data, setSelectedChat, selected_chat, chat_index, handleTopUp }) => {

    const [viewing, setViewing] = useState(false)
    const [applicantDetails, setApplicantDetails] = useState(null)
    const [bottomRef, setBottomRef] = useState(useRef(null))

    const inputRef = useRef(null);


    const sendSystemMesssage = async (message) => {
        const data = {
            message: message,
            sender: user_data.uid,
            tag: 'system'
        }
        await updateOfferChat(offer.id, data)
        bottomRef.current?.scrollIntoView({
            behavior: "smooth",
        })

        const to_name = tag==='client'?offer?.vendorname:offer?.clientname
        const to_mail = tag==='client'?offer?.vendoremail:offer?.clientemail
        const subject = 'APPLYMYCV: NEW ACTION'
        const mail = template(to_name, `New account activity: ${message}`)
        await sendMail(to_mail, subject, mail, user_data?.uid)
    }

    const sendMessage = async () => {

        if (inputRef.current.value.length > 0) {
            const data = {
                message: inputRef.current.value,
                sender: user_data.uid,
                tag: tag
            }
            inputRef.current.value = "";
            await updateOfferChat(offer.id, data)
            bottomRef.current?.scrollIntoView({
                behavior: "smooth",
            })
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            sendMessage();
        }
    };

    const handleInputChange = () => {

    };

    const closeModal = () => {
        setViewing(false)
    };

    const handleDetailsView = async () => {
        const cv = await getCV(offer.clientid)
        console.log(cv)
        setApplicantDetails(cv)
        setViewing(true)
        await sendSystemMesssage('Details Viewed')

    };

    const handleCancel = async () => {
        const data = {
            closed: true,
            canceled: true
        }
        await remmitUserBalance(offer?.clientuid, offer?.total)
        await updateOffer(offer.id, data)
        await sendSystemMesssage('Offer Canceled')
        await sendSystemMesssage('Offer Closed')
    };

    const handlePay = async () => {
        await updateUserBalances(offer.clientuid, offer.vendoruid, offer.total)
        const data = {
            vendorpaid: true,
            closed: true
        }
        await updateOffer(offer.id, data)
        await sendSystemMesssage('Payment Made')
        await sendSystemMesssage('Offer Closed')
    };

    const handlePayRequest = async () => {
        const data = {
            payrequested: true
        }
        await updateOffer(offer.id, data)
        await sendSystemMesssage('Payment Requested')
    };

    const handleOfferAccept = async () => {
        const data = {
            vendoraccepted: true
        }
        await updateOffer(offer.id, data)
        await sendSystemMesssage('Offer Accepted')
    };

    const handleShare = async () => {
        const data = {
            shareddetails: true
        }
        await updateOffer(offer.id, data)
        await sendSystemMesssage('Application Details Shared')
    };

    const handleCommit = async () => {
        if(user_data?.debit>= offer?.total){

        const data = {
            committed: true
        }
        await updateOffer(offer.id, data)
        await commitUserBalance(offer.clientuid, offer.total)
        await sendSystemMesssage('Application Committed')
        }else{
            handleTopUp(true)
        }
    };
    useEffect(() => {

        bottomRef.current?.scrollIntoView({
            behavior: "smooth",
        })
    }, [selected_chat])

    return (
        <div className='w-full rounded flex flex-col border overflow-clip hover:shadow-lg shadow-black'>
            <button className='w-full border-b flex justify-between px-4 py-2 items-center font-semibold bg-white'
                onClick={() => {
                    setSelectedChat(selected_chat === chat_index ? -1 : chat_index)
                }}>
                <span className='text-start flex-1 text-xs'>{tag === 'client' ? offer.vendorname : offer.clientname}</span>
                <div className='flex flex-col justify-between items-end text-[10px] font-light'>
                    <span>{`Apply to ${offer.jobs} job${offer.jobs === 1 ? '' : 's'} for Kes.${new Intl.NumberFormat().format(offer.total)}`}</span>
                    <span>{`${offer.date}`}</span>
                </div>
            </button>
            {selected_chat === chat_index && <>
                <div
                    className="bg-[#0a131a] bg-[url('assets/images/bg.webp')] flex flex-col bg-cover overflow-y-scroll flex-1 max-h-52"
                    style={{ padding: "12px 5%" }}
                >
                    {offer.chat.map((message, idx) => (
                        <Message key={idx} tag={tag} uid={user_data?.uid} message={message} />
                    ))}
                    <div ref={bottomRef} />
                </div>



                {
                    !offer.canceled && !offer.closed &&
                    <div className="flex items-center bg-[#202d33] w-full h-[70px] p-2 gap-2">

                        <input
                            placeholder={`Type message`}
                            className="bg-[#2c3943] rounded-lg outline-none text-sm text-neutral-200 w-full h-full px-3 placeholder:text-sm placeholder:text-[#8796a1]"
                            onChange={handleInputChange}
                            ref={inputRef}
                            onKeyDown={handleKeyDown}
                        //   onBlur={(e) => {
                        //     e.target.focus();
                        //   }}
                        />


                        <button className="inline-flex gap-1 justify-center items-center rounded-md border disabled:bg-gray-300 disabled:text-gray-900 border-transparent bg-green-100 px-4 py-2 text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                            onClick={sendMessage}>
                            {/* <span className='text-sm font-medium'>Send</span> */}
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                            </svg>

                        </button>

                    </div>}
            </>}
            {
                selected_chat === chat_index && offer.shareddetails && !offer.canceled && !offer.closed && tag === 'vendor' && <button
                    onClick={handleDetailsView}
                    className="inline-flex m-2 justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                >
                    View Application Details
                </button>}
            {
                selected_chat === chat_index && !offer.canceled && !offer.closed &&
                <div className='w-full border-t flex justify-between px-4 py-2 items-center font-semibold bg-white'>

                    <button
                        onClick={handleCancel}
                        className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                    >Cancel Offer</button>

                    {
                            !offer.committed ?
                                <>
                                    {tag === 'client' && <button
                                        onClick={handleCommit}
                                        className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                    >
                                        {user_data?.debit>= offer?.total?'Commit to Offer':'Top Up to Commit'}
                                    </button>}

                                    {tag === 'vendor' && <button
                                        disabled={true}
                                        className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                    >
                                        Awaiting Commitment
                                    </button>}
                                </> :
                        !offer.vendoraccepted ?
                            <>
                                {tag === 'client' && <button
                                    disabled={true}
                                    className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                >
                                    Awaiting Offer Acceptance
                                </button>}

                                {tag === 'vendor' && <button
                                    onClick={handleOfferAccept}
                                    className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                >
                                    Accept Offer
                                </button>}
                            </> :
                            !offer.shareddetails ?
                                <>
                                    {tag === 'client' && <button
                                        onClick={handleShare}
                                        className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                    >
                                        Share Application Details
                                    </button>}

                                    {tag === 'vendor' && <button
                                        disabled={true}
                                        className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                    >
                                        Awaiting Application Details
                                    </button>}
                                </> :
                                !offer.payrequested ?
                                    <>
                                        {tag === 'client' && <button
                                            disabled={true}
                                            className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                        >
                                            Awaiting Payment Request
                                        </button>}

                                        {tag === 'vendor' && <button
                                            onClick={handlePayRequest}
                                            className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                        >
                                            Request Payment
                                        </button>}
                                    </>
                                    :
                                    <>
                                        {tag === 'client' && <button
                                            onClick={handlePay}
                                            className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                        >
                                            Pay & Close
                                        </button>}

                                        {tag === 'vendor' && <button
                                            disabled={true}
                                            className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                        >
                                            Awaiting Payment
                                        </button>}
                                    </>
                    }





                </div>
            }



            {<Transition appear show={viewing} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        className="leading-6 text-sm text-gray-900 text-center bg-[#eee9dd80] p-6 flex flex-col justify-center items-center"
                                    >
                                        <span>{`Applicant's Details`}</span>
                                    </Dialog.Title>

                                    {applicantDetails && <div key={applicantDetails.id} className=' max-w-full w-full flex flex-col items-center border-b border-black py-4 space-x-2 h-full'>

                                    <span className='text-xs line-clamp-2 my-2 text-ellipsis'>{applicantDetails?.title} ({applicantDetails?.caption})</span>
                                        <span className='text-xs line-clamp-2 my-2 text-ellipsis'>{applicantDetails?.description}</span>

                                        <div className='flex justify-center w-full'>
                                            <div className={`flex flex-col  justify-start items-stretch max-w-full overflow-hidden rounded border border-black `}>

                                                {applicantDetails?.files?.map((file, idx) => {
                                                    return <a key={idx} target='_blank' href={file.url} className='flex justify-start items-center space-x-4 hover:bg-red-50 px-4 py-2 hover:underline decoration-wavy' rel="noreferrer">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                                        </svg>
                                                        <span className='text-xs'>{file.name}</span>
                                                    </a>
                                                }
                                                )}
                                            </div>
                                        </div>

                                    </div>}
                                    <button
                                        onClick={closeModal}
                                        className="inline-flex m-4 justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                    >Close</button>

                                </Dialog.Panel>

                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>}

        </div>
    )
}

//             <ProgressBar
//                 className="h-10 w-full"
//                 striped
//                 animated
//                 variant={
//                     (100 * shared.credit / shared.charge) > 80 ? "success" : (100 * shared.credit / shared.charge) > 40 ? "warning" : "danger"
//                 }
//                 now={(100 * shared.credit / shared.charge)}
//                 label={`${Math.round(100 * shared.credit / shared.charge)}%`}
//             //   visuallyHidden
//             />

export default OfferCard