// import VideoThumb from '@/public/images/hero-image.png'
// import ModalVideo from '@/components/modal-video'

import { useNavigate } from "react-router-dom";
import { COLLECTIONS, db, getUser, signInWithGoogle } from "../firebaseConfig"
import { useEffect, useState } from "react";
import { handleSignIn, sleep, validatePhoneNumber } from "../helpers";
import { useDocument } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import ReactLoading from 'react-loading';
import ImageToLink from "./ImageToLink";

export default function Hero({ user, handleClick, tab }) {
  const navigate = useNavigate()

  const periods = [
    { key: 'day', days: 1, min: 69, moment: 1 },
    { key: 'week', days: 7, min: 299, moment: 1 },
    { key: 'month', days: 28, min: 999, moment: 1 },
    // { key: 'year', days: (28 * 13), min: 4999, moment: 1 },
  ]

  const [period, setPeriod] = useState(periods[0])
  const [user_data, setUser] = useState(null)
  const [prompting, setPrompting] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [msisdn, setmsisdn] = useState('')

  const [value, loading, error] = useDocument(doc(db, COLLECTIONS.CONSTS, "app"), { snapshotListenOptions: { includeMetadataChanges: true } });

  const handleUser = (user) => {
    setUser(user)
    setmsisdn(user.phone)
  }
  const handleFetch = (id) => {
    user && getUser(id, handleUser).then((unsub) => { })
  }
  const handleMSISDNChange = (event) => {
    const phoneNumber = event.target.value
    const result = validatePhoneNumber(phoneNumber);

    if (result.result !== "invalid") {
      setmsisdn(result.result);
    } else {
      setmsisdn('')
    }
  }

  const handlePeriod = (p) => {
    setPeriod(p)
  }

  const SendPayload = async (payload, { setPrompting, setWaiting }) => {

    console.log(payload)

    if (payload.amount === '' || payload.msisdn === '') {
      alert("Missing info")
    } else {
      setPrompting(true);
      setWaiting(true);
      const url = `https://us-central1-firenode-86abd.cloudfunctions.net/app/gateway`;
      const body = `amount=${payload.amount}&msisdn=${payload.msisdn}&account_id=subscription/${payload.account_id}`;
      fetch(url, {
        body: body,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        method: "POST",
      })
        .then((response) => response.json())
        .then(async (data) => {
          console.log(data)

          if (data.success) {
            console.log('ok')


            // await prelovedUpdate(payload.account_id, { active: true })
          } else {

          };
          await sleep(12000)
          setWaiting(false)
          setPrompting(false)

        })
        .catch((error) => {
          console.error('Error:', error)
          setPrompting(false)
          setWaiting(false)
          alert(error)
        });
    }
  }

  const handleSignIn = async () => {
    await signInWithGoogle()
    // handleAccount()
  }


  useEffect(() => {
    user && handleFetch(user.uid)
  }, [user])

  return (
    <section className="pt-8 md:pt-10 px-4  relative">
      {true ?
        <div className="flex flex-col items-center justify-start max-w-6xl mx-auto sm:px-6">
          {/* Hero content */}
          <div className="flex flex-col">
            {/* Section header */}
            <div className="text-center   flex-1 flex justify-start items-center flex-col">
              {user ?
                <h1 className="text-5xl md:text-6xl font-extrabold tracking-tighter mb-4 line-clamp-3" data-aos="zoom-y-out">
                  <span className="bg-clip-text px-1 text-transparent bg-gradient-to-r from-blue-500 to-teal-400">
                    {tab !== 3 ? `Account Balance: Kes. ${new Intl.NumberFormat().format(user_data?.debit ?? 0)}` : `Account Earnings: Kes. ${new Intl.NumberFormat().format(((user_data?.credit ?? 0) * .7).toFixed(2))}`}
                  </span>
                </h1>
                :
                <div className="flex flex-col space-y-8 mb-4 items-center max-w-lg">
                  <h1 className="text-5xl md:text-6xl font-extrabold tracking-tighter" data-aos="zoom-y-out">
                    <span className="bg-clip-text px-1 text-transparent bg-gradient-to-r from-blue-500 to-teal-400">
                      {'Studies show that it takes up to 200 applications to land a job..'}
                    </span>
                  </h1>
                  <span className="">ApplymyCV helps you submit MORE job applications FASTER by connecting you with Assistants who behind the scenes work to apply to <b>Job Opportunities</b> on your behalf!</span>
                  <span className="">Simply post an offer!</span>

                  <div className="hidden flex-col items-start space-y-4">

                    <ul className="text-start text-sm list-disc pl-4 space-y-2">
                      <li><strong>Effortless Applications:</strong> Upload your CV, describe your dream job, and connect with our network of Assistants.</li>
                      <li><strong>Your Silent Job Search Team:</strong> Apply to as many jobs offers as possible by partnering with as many Assistants as you can.</li>
                      <li><strong>Focus on What Matters:</strong> Free up your time to focus on interview prep and landing your dream role.</li>
                    </ul>
                  </div>

                </div>
              }




              {/* <div className="max-w-3xl mx-auto">
                <div className="flex justify-center flex-wrap">
                  <div className="card">

                    <button className="card-front-image card-image m-4 rounded py-4 px-6 text-white bg-blue-600 hover:bg-blue-700 "
                      onClick={handleSignIn}>
                      <span className="w-full mb-4 px-4 sm:w-auto sm:mb-0" href="#0">Come on In.</span>
                    </button>
                    <div class="card-faders w-full">
                      {Array(8).fill().map(() => 
                      <button className="card-fader card-image m-4 rounded py-4 px-6 text-white bg-blue-400 ">
                        <span className="w-full mb-4 sm:w-auto sm:mb-0" href="#0">Hop on and Sell!</span>
                      </button>)
                      }
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {/* <span className=" font-bold text-lg text-orange-600">Explore Vendors</span> */}
        </div>
        :
        <div className=' flex-col hidden justify-between items-center pt-4 flex-wrap md:pb-4'>

          {/* <span className='font-semibold text-xl text-center'>Vendors near me</span> */}
          {/* 
          {vendable && <button
            onClick={() => handleClick()}
            className='rounded-full mb-4 px-4 md:px-8 py-2 bg-orange-400 text-white hover:shadow-lg'
          >
            {item?.isVendor ?
              <span></span> : <span>Become a vendor</span>}
          </button>} */}
        </div>
      }
    </section>
  )
}