import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { getRequest, getUser } from '../firebaseConfig';
import { summarizeListWithCount, validatePhoneNumber } from '../helpers';
import ReactLoading from 'react-loading';

const Request = ({ prod, user }) => {
    const params = useParams();
    const [item, setItem] = useState(null)
    const [user_data, setUser] = useState(null)
    const [list, setList] = useState(null)
    const [msisdn, setmsisdn] = useState('');
    const [prompting, setPrompting] = useState(null)
    const SendPayload = async (payload, { setPrompting, setWaiting }) => {

        const sleep = async (ms) => { return new Promise(resolve => setTimeout(resolve, ms)); }
        console.log(payload)

        if (payload.amount === '' || payload.msisdn === '') {
            alert("Missing info")
        } else {
            setPrompting(true);
            const url = `https://us-central1-firenode-86abd.cloudfunctions.net/app/gateway`;
            const body = `amount=${payload.amount}&msisdn=${payload.msisdn}&account_id=${payload.account_id}`;
            fetch(url, {
                body: body,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "POST",
            })
                .then((response) => response.json())
                .then(async (data) => {

                    if (data.success === true) {

                        // console.log("resp", data)
                    } else {

                    };
                    await sleep(20000)
                    setPrompting(false)

                })
                .catch((error) => {
                    console.error('Error:', error)
                    setPrompting(false)
                    alert(error)
                });
        }
    }
    const handleFetch = () => {
        user && getUser(user.uid, setUser).then((unsub) => {})
        if (params.id && params.id !== '') {
            getRequest(params.id, setItem, process).then((unsub) => {
                if (item?.paid) {
                    unsub()
                    console.log('paid')
                } else {
                    console.log('unpaid')
                }
            })
        }
    }
    const process = (item) => {
        const texts = []
        var total = 0
        item.cart.forEach((item, idx) => {
            var charge = item.discounted ? item.discount : item.price
            // texts.push(`${item.name}${item.discounted?` ~@${item.price}/=~`:''} @ _${charge}/=_`)
            texts.push(`${item.name}`)
            total += charge
        })
        setList(summarizeListWithCount(texts))
        const date = item.logged.toDate()
        const time = date.toLocaleTimeString([], { hour12: true, hour: "2-digit", minute: "2-digit" })
        const shortdate = date.toLocaleDateString("en-GB")


        const data = {
            id: item.id,
            date: `${shortdate}-${time}`,
            total: total,
            phone: user_data?.phone ?? '',
            title: item.vendor.title,
            vid: item.vendor.id,
            paid: item.paid,
            image: item.vendor.image,
            prompt: item.vendor.prompt,
            debitedAt: item.debitedAt ? `${item.debitedAt.toDate().toLocaleDateString("en-GB")}-${item.debitedAt.toDate().toLocaleTimeString([], { hour12: true, hour: "2-digit", minute: "2-digit" })}` : ''
        }
        setmsisdn(data.phone)
        return data
    }

    const handleMSISDNChange = (event) => {
        const phoneNumber = event.target.value
        const result = validatePhoneNumber(phoneNumber);

        if (result.result !== "invalid") {
            setmsisdn(result.result);
        } else {
            setmsisdn('')
        }
    }

    useEffect(() => {
        handleFetch()
    }, [user]);
    return (

        <div className='pt-20 flex flex-col justify-center items-center px-4'>
            <Link to={`/${item?.vid}`} className='flex flex-col justify-center md:justify-between items-center py-2 flex-wrap'>
                <img alt='' src={item?.image} className={`w-24 h-24 my-4 object-cover object-center rounded-full bg-slate-500`} loading='lazy' />

                <span className='font-semibold text-xl text-center'>{`${item?.title ?? '--'}`}</span>
                <span className='font-light font-mono text-xs text-center'>{`${item?.date ?? '--/--/----'}`}</span>
            </Link>


            <div className='flex flex-col justify-center md:justify-between items-center py-2 mt-8 flex-wrap'>
                {/* <span className='font-bold text-md pt-8'>REQUEST</span> */}
                {list ? list.map((i) => <p>{i}</p>)
                    : <p>--</p>}
            </div>

            <div className='flex flex-col justify-center md:justify-between items-center py-2 flex-wrap'>
                <span className='font-bold text-md pt-8'>Est. TOTAL</span>
                <span>{item?.total ?? "--"}/=</span>
            </div>


            {


                item?.paid ?
                    <span className='font-light font-mono text-xs text-center'>Paid At: {`${item?.debitedAt}`}</span>
                    :
                    item ?
                        <div className='flex flex-col justify-center md:justify-between items-center mt-8 flex-wrap'>
                            {false//item?.prompt
                             ?

                                <>
                                    <label className="text-md font-semibold" >{"Prompt to Pay"}</label>
                                    <span className='text-[10px] mt-1'>(<b>Note:</b> prompting has an 11% service fee)</span>
                                    <input placeholder='Your Mpesa Number'
                                        type='tel'
                                        onChange={(e) => handleMSISDNChange(e)}
                                        name="msisdn"
                                        defaultValue={`${msisdn === '' ? '' : '+'}${msisdn}`}
                                        className={`p-4 mt-2 text-center font-semibold cursor-default overflow-hidden rounded-lg bg-white  shadow-md ring-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ${msisdn === '' ? 'ring-red-100 focus-visible:ring-offset-red-300' : 'ring-teal-100 focus-visible:ring-offset-teal-300'} sm:text-sm`}
                                    />
                                    {(prompting) ?
                                        <ReactLoading className="loading-anim" type="bubbles" color="green" />
                                        : <button
                                            disabled={(msisdn === '')}
                                            className="m-4 inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                            onClick={() => {
                                                SendPayload({
                                                    amount: Math.ceil(item?.total),
                                                    msisdn: msisdn,
                                                    account_id: `${item?.id}/${item.vid}`
                                                }, { setPrompting }
                                                )
                                            }}
                                        >Prompt
                                        </button>
                                    }
                                </>
                                :
                                <>
                                    <span className='font-light font-mono text-xs text-center'>Request the vendor for payment details</span>
                                </>
                            }
                        </div>
                        :
                        <ReactLoading className="loading-anim" type="bubbles" color="black" />
            }


        </div>
    )
}

export default Request