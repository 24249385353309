import React, { Fragment, useEffect, useState } from 'react'
import { addToOffers, commitUserBalance, sendMail, signInWithGoogle, template, updateOfferChat } from '../firebaseConfig'
import { Dialog, Transition } from '@headlessui/react'
import ReactLoading from 'react-loading';
import TopUpCard from './TopUpCard';
import CVUpload from './CVUpload';

const AssistantCard = ({ assistant, cv, client, setSelectedTab }) => {
    const [jobs, setJobs] = useState(0)
    const [total, setTotal] = useState(0)
    const [adding, setAdding] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [desc, setDesc] = useState('')
    const [topup, setTopup] = useState(false)
    // console.log(cv)

    const handleAmountChange = (e) => {
        const input = e.target.value !== '' ? parseInt(e.target.value) : 0
        setJobs(input)
        const t = input * assistant.rate
        setTotal(t)
        setTopup(client?.debit < t)
    }

    const handleDescChange = (event) => {
        setDesc(event.target.value);
    }

    const closeModal = () => {
        setAdding(false)
        setUploading(false)
    }

    const openModal = () => {
        setAdding(true)
    }

    const handleOffer = async () => {
        setUploading(true)
        const data = {
            vendorid: assistant.id,
            clientid: cv.id,
            vendoruid: assistant.uid,
            clientuid: client?.uid,
            vendorname: assistant?.name,
            clientname: client?.name,
            vendoremail: assistant?.email,
            clientemail: client?.email,
            chat: [],
            jobs: jobs,
            rate: assistant.rate,
            total: total,
            status: { client: 1, vendor: 0 },
            rating: { value: 0, remark: '' },
            desc: desc,
            committed: true,
        }

        await addToOffers(data, async (offer) => {
            await commitUserBalance(client?.uid, total)
            setSelectedTab(2)

            const message = {
                tag: 'client',
                message: data.desc,
                sender: data.clientuid,
            }
            await updateOfferChat(offer.id, message)
            const subject = 'APPLYMYCV: NEW PARTNERSHIP OFFER'
            const mail = template(assistant?.name, `${client?.name} has made you a PARTNERSHIP offer!`)
            await sendMail(assistant?.email, subject, mail, client?.uid)
            closeModal()
        })
    }

    useEffect(() => {
        setTopup(client?.debit < total)
    }, [client, total])
    return (
        <div className='w-full rounded flex flex-col border'>
            <div className='w-full border-b flex justify-between px-8 py-2 items-center text-xs font-semibold'>
                <span className='flex-1 text-start'>{assistant.name}</span>
                <span>Kes. {assistant.rate} per Application</span>

            </div>
            <span className='text-xs px-4 py-4 line-clamp-6 overflow-auto'>{assistant.desc}</span>

            <div className='w-full border-t flex justify-between px-4 py-2 items-center text-sm'>

                <button className="inline-flex justify-center items-center text-sm text-black font-light gap-1"
                    onClick={() => { }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
                    </svg>
                    <span>New</span>
                </button>
                <button className="inline-flex justify-center rounded-md border disabled:bg-gray-300 disabled:text-gray-900 border-transparent bg-green-100 px-4 py-1 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                    onClick={openModal}>
                    Send Offer
                </button>

            </div>


            {<Transition appear show={adding} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        className="leading-6 text-sm text-gray-900 text-center bg-[#eee9dd80] p-6 flex flex-col justify-center items-center"
                                    >
                                        <span>{`Send an offer to ${assistant.name}`}</span>
                                        <span>{`Rate: Kes.${new Intl.NumberFormat().format(assistant.rate)} per Application`}</span>
                                        <span>{`Offer Total: Kes.${new Intl.NumberFormat().format(total)}`}</span>
                                    </Dialog.Title>

                                    {cv && <div className=' max-h-[60vh] overflow-auto px-4 bg-white py-8 flex flex-col gap-4'>


                                        <input placeholder='Desired number of job applications' type='number' onChange={(e) => handleAmountChange(e)}
                                            className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />
                                        {topup && <span className='text-red-800 font-semibold text-xs'>{'Offer Amount exceeds Account Balance. Kindly Top up'}</span>}

                                        {!topup && <textarea className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm"
                                            placeholder='Opening statement' type='text' onChange={(e) => handleDescChange(e)} />}
                                    </div>}
                                    {!cv ?
                                        <CVUpload user_data={client}  close={closeModal}/>
                                        : topup ?
                                            <TopUpCard user_data={client} initial_amount={total} />
                                            :
                                            uploading ?
                                                <div className='flex justify-center items-center'>
                                                    <ReactLoading type="bubbles" color="black" />
                                                </div>
                                                : <div className='p-4 flex justify-around items-center'>

                                                    <button
                                                        onClick={closeModal}
                                                        className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                                    >Close</button>

                                                    {client ?
                                                        <button
                                                            disabled={topup || total === 0}
                                                            onClick={handleOffer}
                                                            className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-green-900 bg-green-100 hover:bg-green-200 disabled:text-gray-900 disabled:bg-gray-100 disabled:hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                                        >
                                                            Send Offer
                                                        </button> :
                                                        <button
                                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-200 px-4 py-2 text-sm font-medium disabled:bg-gray-300 text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                            onClick={() => { signInWithGoogle() }}
                                                        >{'Sign In'}</button>
                                                    }
                                                </div>
                                    }
                                </Dialog.Panel>

                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>}
        </div>
    )
}

export default AssistantCard