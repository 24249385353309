import { doc } from 'firebase/firestore'
import React, { Fragment, createRef, useEffect, useRef, useState } from 'react'
import { useDocument } from 'react-firebase-hooks/firestore'
import { COLLECTIONS, addSharable, addToApplicants, addToAssistants, addToCollection, anon, captureResume, db, getApplicants, getAssistants, getCVs, getReceivedOffers, getSentOffers, getShared, getUser, getUserShares, incrementView, signInWithGoogle, updateShared, updateSharedImage, updateUser } from '../firebaseConfig'
import { calculateCashValueWithDiscount, getMoment, shorten, sleep, validatePhoneNumber, wamessage } from '../helpers'
import ImageToLink from '../components/ImageToLink'
import ReactLoading from 'react-loading';
import { Alert, Collapse } from '@mui/material'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Dialog, Switch, Transition } from '@headlessui/react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import ProgressBar from "react-bootstrap/ProgressBar";
import Hero from '../components/Hero'
import OfferCard from '../components/OfferCard'
import AssistantCard from '../components/AssistantCard'
import ApplicantCard from '../components/ApplicantCard'
import TopUpCard from '../components/TopUpCard'
import CVUpload from '../components/CVUpload'
import Applicants from '../components/Applicants'

const WhatsappEverClear = ({ user }) => {
    const params = useParams();
    const nav = useNavigate();
    const max = 50
    const min_debit = 13
    const free = true
    const mom = getMoment()
    const tabs = ['Applicants', 'Assistant Offers', 'Sent Offers', 'Received Offers']
    const [selected_tab, setSelectedTab] = useState(0)
    const [selected_chat, setSelectedChat] = useState(-1)
    const periods = [
        // { key: 'day', days: 1, min: 50, moment: 1 },
        { key: 'week', days: 7, min: 350, moment: 1 },
        { key: 'month', days: 28, min: 1000, moment: 1 },
        { key: 'year', days: (28 * 13), min: 8000, moment: 1 },
    ]
    const [topup, setTopup] = useState(false)
    const [rate, setRate] = useState(0)
    const [total, setTotal] = useState(0)
    const [dominantColor, setDominant] = useState('')
    const [link, setLink] = useState(null)
    const [frame, setFrame] = useState(null)
    const [adding, setAdding] = useState(null)
    const [offering, setOffering] = useState(false)
    const [shared, setShared] = useState(null)
    const [locked, setLocked] = useState(true)
    const [amount, setAmount] = useState(0)
    const [jobs, setJobs] = useState(0)
    const [discount, setDiscount] = useState(0.0)
    const [adult, setAdult] = useState(false)
    const [lock, setLock] = useState(false)
    const [hidden, setHidden] = useState(false)
    const [unlocking, setUnlocking] = useState(null)
    const [charge, setCharge] = useState(0)
    const [shareCopy, setShareCopy] = useState(null)
    const [sent_offers, setSentOffers] = useState([])
    const [cvs, setCVs] = useState([])
    const [received_offers, setReceivedOffers] = useState([])
    const [applicants, setApplicants] = useState([])
    const [assistants, setAssistants] = useState([])
    const [shares, setShares] = useState([])
    const [copied, setCopy] = useState(false)
    const [creating, setCreating] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [shareLoaded, setShareLoaded] = useState(false)
    const [name, setName] = useState('')
    const [caption, setCaption] = useState('')
    const [desc, setDesc] = useState('')
    const [files, setFiles] = useState([])



    const closeListing = () => {
        setUploading(false)
        setFiles([])
    }
    const handleNameChange = (event) => {
        setName(event.target.value);
    }
    const handleDescChange = (event) => {
        setDesc(event.target.value);
    }
    const handleCaptionChange = (event) => {
        setCaption(event.target.value);
    }
    const handleFileChange = (event) => {
        const newFiles = event.target.files;
        setFiles([...files, ...newFiles].slice(0, max));
        getDominantColor(newFiles[0])
            .then(dominantColor => {
                console.log("Dominant color:", dominantColor);
            })
            .catch(error => {
                console.error("Error getting dominant color:", error);
            });
    }
    const handleFileAdd = (event) => {
        const newFiles = event.target.files;
        setFiles([...files, ...newFiles].slice(0, max));
        getDominantColor(newFiles[0])
            .then(dominantColor => {
                console.log("Dominant color:", dominantColor);
            })
            .catch(error => {
                console.error("Error getting dominant color:", error);
            });
    }
    const handleShareClose = () => {
        setCopy(false);
        closeListing()
        closeModal()
    }
    const handleShareCopy = () => {
        setCopy(true);
    }
    const openModal = () => {
        setUploading(false)
        setLink(null)
        setCopy(false)
    }

    const closeModal = () => {
        setCreating(false)
        setFiles([])
        setAdding(null)
        setUploading(false)
        setLink(null)
        setName('')
        setCaption('')
        setDesc('')
        setJobs(0)
        setTotal(0)
        setRate(0)
        setOffering(false)
    }

    const closeAddModal = () => {
        setAdding(null)
        setFiles([])
        setUploading(false)
    }
    const closeFrameModal = () => {
        setFrame(null)
    }
    const getDominantColor = async (file) => {
        // Create an object URL for the image (temporary URL)
        const url = URL.createObjectURL(file);

        // Create a new image element
        const image = new Image();
        image.src = url;

        // Wait for the image to load asynchronously using await
        try {
            await new Promise((resolve, reject) => {
                image.onload = resolve;
                image.onerror = reject;
            });
        } catch (error) {
            console.error("Error loading image:", error);
            URL.revokeObjectURL(url); // Clean up even on error
            throw error; // Re-throw the error for further handling (optional)
        }


        // Create a hidden canvas element
        const canvas = document.createElement('canvas');
        canvas.width = canvas.height = 1;
        const context = canvas.getContext('2d');

        // Draw the image onto the canvas (scaled down to a single pixel)
        context.drawImage(image, 0, 0);

        // Get the image data for the single pixel
        const imageData = context.getImageData(0, 0, 1, 1).data;

        // Extract the RGB values
        const red = imageData[0];
        const green = imageData[1];
        const blue = imageData[2];

        // Convert RGB to HEX (adapted for readability)
        const hex = "#" + ((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1).padStart(6, '0');
        setDominant(hex)
        return hex.toUpperCase();
    }
    const handleUpload = async () => {
        setUploading(true)

        if (files.length > 0) {
            const data = {
                hits: 0,
                title: name,
                uid: user?.uid ?? '',
                caption: caption,
                phone: user_data?.phone ?? '',
                description: desc,
                dominantColor: dominantColor,
                adult: adult,
                lock: lock,
                link: link,
                hidden: hidden,
                charge: charge
            }
            await captureResume(files, data, closeModal, {
                public: true,
            })
        } else {
            setUploading(false)
            console.log('no file')
        }
    }

    const [period, setPeriod] = useState(periods[1])
    const [user_data, setUser] = useState(null)
    const [prompting, setPrompting] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [msisdn, setmsisdn] = useState('')

    const [value, loading, error] = useDocument(doc(db, COLLECTIONS.CONSTS, "app"), { snapshotListenOptions: { includeMetadataChanges: true } });

    const handleMSISDNChange = (event) => {
        const phoneNumber = event.target.value
        const result = validatePhoneNumber(phoneNumber);

        if (result.result !== "invalid") {
            setmsisdn(result.result);
        } else {
            setmsisdn('')
        }
    }

    const handleJobsChange = (e) => {
        const input = e.target.value !== '' ? parseInt(e.target.value) : 0
        setJobs(input)
        const t = input * rate
        setTotal(t)
        setTopup(user_data?.debit < t)
    }

    const handleRateChange = (e) => {
        const input = e.target.value !== '' ? parseInt(e.target.value) : 0
        setRate(input)
        const t = input * jobs
        setTotal(t)
        setTopup(user_data?.debit < t)
    }

    const handleOffer = async () => {
        setUploading(true)
        const data = {
            uid: user_data?.uid,
            jobs: jobs,
            rate: rate,
            total: total,
            clientid: shares?.[0]?.id,
            description: shares?.[0]?.description,
            name: user_data?.name,
            email: user_data?.email,
            image: user_data?.image,
            hero: user_data?.hero,
            expectations: desc,
            claims: []
        }

        await addToApplicants(data, () => {
            handleApplicants()
            closeModal()
        })
    }

    const handleAmountChange = (e) => {
        const input = e.target.value !== '' ? parseInt(e.target.value) : 0
        setAmount(input)
    }


    const SendPayload = async (payload, { setPrompting, setWaiting }) => {

        console.log(payload)

        if (payload.amount === '' || payload.msisdn === '') {
            alert("Missing info")
        } else {
            setPrompting(true);
            setWaiting(true);
            const url = `https://us-central1-firenode-86abd.cloudfunctions.net/app/gateway`;
            const body = `amount=${payload.amount}&msisdn=${payload.msisdn}&account_id=subscription/${payload.account_id}/${jobs}`;
            fetch(url, {
                body: body,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "POST",
            })
                .then((response) => response.json())
                .then(async (data) => {
                    console.log(data)

                    if (data.success) {
                        console.log('ok')
                        await updateUser(payload.account_id, { phone: payload.msisdn })
                    } else {

                    };
                    await sleep(12000)
                    setWaiting(false)
                    setPrompting(false)
                    setCreating(false)

                })
                .catch((error) => {
                    console.error('Error:', error)
                    setPrompting(false)
                    setWaiting(false)
                    alert(error)
                });
        }
    }

    const [isDragging, setIsDragging] = useState(false)
    const fileInputRef = useRef(null)
    const addFileRef = useRef(null)

    const handleFiles = (newfiles) => {
        console.log('vbbc')
        if ((newfiles.length === 0)) return
        [...newfiles].forEach((file) => {
            file.type?.includes('image') && getDominantColor(newfiles[0])
                .then(dominantColor => {
                    console.log("Dominant color:", dominantColor);
                })
                .catch(error => {
                    console.error("Error getting dominant color:", error);
                });

        });
        setFiles([...files, ...([...newfiles].filter((f) => f.type?.includes('image')))].slice(0, max))

    }

    const handleHide = async (id, hidden_state) => {
        await updateShared(id, { hidden: hidden_state })
    }

    const onDragOver = (e) => {
        e.preventDefault()
        setIsDragging(true)
    }
    const onDragLeave = (e) => {
        e.preventDefault()
        setIsDragging(false)
    }
    const onDrop = (e) => {
        e.preventDefault()
        setIsDragging(false)
        const files = e.dataTransfer.files
        handleFiles(files)
    }
    const handleSaveCopy = async (id) => {
        setShareCopy(null)
        setCopy(false)
        const lnk = `https://applymycv.com/${id}`//await shorten(`https://us-central1-autosoft-614e7.cloudfunctions.net/socialShare/share/${id}`)
        setLink(lnk)
        console.log(id)
        setShareCopy(id)

    }

    const handleAdd = async () => {

        setUploading(true)

        const data = {
            uid: user_data.uid,
            name: name,
            email: user_data.email,
            rate: amount,
            desc: desc,
            ratings: [],
            image: '',
            hero: ''
        }
        console.log('adding', data)
        await addToAssistants(data, closeAddModal)
        handleAssistants()

    }

    const handleUnlock = async (e, usr = user) => {

        if (usr !== null) {

            const payload = {
                amount: amount,
                msisdn: msisdn,
                account_id: usr?.uid
            }
            if (payload.amount === '' || payload.msisdn === '') {
                alert("Missing info")
            } else {
                setPrompting(true);
                setWaiting(true);
                const url = `https://us-central1-firenode-86abd.cloudfunctions.net/app/gateway`;
                const body = `amount=${payload.amount}&msisdn=${payload.msisdn}&account_id=unlock/${payload.account_id}/${shared.uid}:${shared.id}`;
                fetch(url, {
                    body: body,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then(async (data) => {

                        await sleep(12500)
                        if (data.success) {
                            console.log('ok')
                            await updateUser(payload.account_id, { phone: payload.msisdn })
                        } else {

                        };
                        setWaiting(false)
                        setPrompting(false)
                        setUnlocking(null)

                    })
                    .catch((error) => {
                        console.error('Error:', error)
                        setPrompting(false)
                        setWaiting(false)
                        alert(error)
                    });
            }

        } else {
            setPrompting(true);
            setWaiting(true);
            console.log('anonning')
            anon(handleUnlock)
        }
    }

    const handleUser = (user) => {
        // console.log(user)
        setUser(user)
        setmsisdn(user?.phone)
        setName(user?.name)
    }
    const handleShared = (data) => {
        // console.log(data)
        const locked = ((user?.uid !== data.uid) && data.lock ? !(user_data?.purchases ?? []).includes(data.id) : false)
        setShared(data)
        setLocked(locked)
        if (locked) {
        }
    }

    const handleImageUpdate = async (id, img, idx) => {
        const data = {
            pos: img.pos ?? idx,
            url: img.url,
            link: img.link ?? '',
            caption: img.caption,
            time: img.time,
            public: !(img.public ?? false)
        }
        await updateSharedImage(id, data, img)
    }

    const handleCollectionChange = async (data) => {
        setShared(null)
        await incrementView(data)
        nav(`/${data.id}`)
    }


    const handleChat = (share) => {
        const date = new Date()
        const time = date.toLocaleTimeString([], { hour12: true, hour: "2-digit", minute: "2-digit" }).toUpperCase()
        const shortdate = date.toLocaleDateString("en-GB")
        const title = `https://applymycv.com/${share.id}`
        const text = 'Hey there! I saw your collection on ApplymyCV and I would like to chat.'
        const m = `${title}\n*ApplymyCV* ${'```'}${shortdate}-${time}${'```'}\n\n${text}`
        window.open(`https://wa.me/${share.phone}?text=${wamessage(m)}`, '_blank');
    }

    const handleCall = (share) => {
        window.open(share, '_blank');
    }
    const handleApplicants = async () => {

        const applicants = await getApplicants()
        setApplicants(applicants)
    }

    const handleAssistants = async () => {

        const assistants = await getAssistants()
        setAssistants(assistants)
    }

    const handleCVs = async () => {

        const cvs = await getCVs()
        // console.log()
        setCVs(cvs)
    }

    useEffect(() => {
        params.id && getShared(params.id, handleShared, setShares, setShareLoaded)

        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, [params])

    useEffect(() => {
        setTopup(user_data?.debit < total)
    }, [user_data, total])


    useEffect(() => {
        user && getUser(user?.uid, handleUser).then((unsub) => { })
        user && getUserShares(user?.uid, setShares).then((unsub) => { })
        handleApplicants()
        handleAssistants()
        handleCVs()
        user && getSentOffers(user?.uid, setSentOffers).then((unsub) => { })
        user && getReceivedOffers(user?.uid, setReceivedOffers).then((unsub) => { })
    }, [user])

    return (
        <div className='flex flex-col   justify-around items-center pt-20 pb-16' style={{ backgroundColor: '#00000000' }}
            onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
            {isDragging &&
                <div className='absolute z-20 top-0 left-0 right-0 bottom-0 bg-white bg-opacity-80'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-20 h-20 absolute top-0 right-0">
                        <path fillRule="evenodd" d="M14.78 5.22a.75.75 0 00-1.06 0L6.5 12.44V6.75a.75.75 0 00-1.5 0v7.5c0 .414.336.75.75.75h7.5a.75.75 0 000-1.5H7.56l7.22-7.22a.75.75 0 000-1.06z" clipRule="evenodd" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-20 h-20 absolute top-0 left-0">
                        <path d="M6.28 5.22a.75.75 0 00-1.06 1.06l7.22 7.22H6.75a.75.75 0 000 1.5h7.5a.747.747 0 00.75-.75v-7.5a.75.75 0 00-1.5 0v5.69L6.28 5.22z" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-20 h-20 absolute bottom-0 right-0">
                        <path fillRule="evenodd" d="M14.78 14.78a.75.75 0 01-1.06 0L6.5 7.56v5.69a.75.75 0 01-1.5 0v-7.5A.75.75 0 015.75 5h7.5a.75.75 0 010 1.5H7.56l7.22 7.22a.75.75 0 010 1.06z" clipRule="evenodd" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-20 h-20 absolute bottom-0 left-0">
                        <path fillRule="evenodd" d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z" clipRule="evenodd" />
                    </svg>
                    <div className='w-full h-full flex justify-center items-center space-x-4'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-28 h-28">
                            <path fillRule="evenodd" d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z" clipRule="evenodd" />
                        </svg>
                        <span className='font-bold text-4xl'>Drop files anywhere</span>
                    </div>
                </div>}

            {!params.id && <Hero user={user_data} handleClick={() => { }} tab={selected_tab} />}

            <div className='flex flex-wrap w-full max-w-md gap-4 px-4'>

                {user && <button className="flex flex-1 justify-center min-w-fit items-center space-x-2 rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={() => {
                        setLink('')
                        setCaption('')
                        setCreating(true)
                    }}
                    disabled={user && (shares?.length > 0) && (selected_tab === 2) && (((user_data?.credit ?? 0) * .7).toFixed(2) < 2500)}
                >
                    {user ?
                        shares?.length > 0 ?

                            <>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
                                </svg>
                                <span>{selected_tab !== 3 ? `Top up!` : ((user_data?.credit ?? 0) * .7).toFixed(2) >= 2500 ? `Request Withdrawal` : `Min withdrawal Kes.2,500`}</span>
                            </>
                            :
                            <>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
                                </svg>
                                <span>{'Upload CV'}</span>
                            </>
                        :
                        <>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.182 15.182a4.5 4.5 0 0 1-6.364 0M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Z" />
                            </svg>
                            <span>{'Get Started!'}</span>
                        </>

                    }
                </button>}


                {/* {!user && <button className="flex flex-1 justify-center min-w-fit items-center space-x-2 rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={() => { setAdding(true) }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z" />
                    </svg>

                    <span>{'Register as an Assistant'}</span>
                </button>} */}

                {!user && <button className="flex flex-1 justify-center min-w-fit items-center space-x-2 rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={() => { signInWithGoogle() }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z" />
                    </svg>

                    <span>{"Get Started!"}</span>
                </button>}

            </div>


            {/* home page */}
            {user && !params.id && shares &&
                <div className='flex flex-col w-full max-w-md px-8'>


                    {
                        shares.filter((s) => s.id !== params.id && !s.hidden).sort((x, y) => x.hits - y.hits).map((share, idx) => {
                            const is_user = user?.uid === share.uid
                            const lim = is_user ? 3 : 4
                            // console.log(share)
                            return (
                                <div key={share.id} className=' max-w-full w-full flex flex-col items-center border-b border-black py-4 space-x-2 h-full'>

                                    <span className='text-xs line-clamp-2 my-2 text-ellipsis'>{share.title} ({share.caption})</span>

                                    <div className='flex justify-center w-full'>
                                        <div //onClick={() => handleCollectionChange(share)}
                                            className={`flex flex-col  justify-start items-stretch max-w-full overflow-hidden rounded border border-black `}>

                                            {share.files?.sort((a, b) => b.public - a.public)?.slice(0, lim)?.map((file, idx) => {
                                                return <a key={idx} target='_blank' href={file.url} className='flex justify-start items-center space-x-4 hover:bg-red-50 px-4 py-2 hover:underline decoration-wavy' rel="noreferrer">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                                    </svg>
                                                    <span className='text-xs'>{file.name}</span>
                                                </a>

                                            }
                                            )}
                                            {share.files?.length > lim && <div className='w-12 md:w-20  m-1 flex justify-center items-center rounded'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                                                </svg>
                                            </div>}
                                        </div>
                                        {/* {is_user &&
                                            <button className={`w-12 md:w-20 m-1 bg-white text-red-900 flex justify-center items-center rounded`}
                                                onClick={() => {
                                                    setLink('')
                                                    setCaption('')
                                                    setAdding(share)
                                                }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                </svg>
                                            </button>} */}
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
            }
            {user_data && <span className="w-full pt-4 max-w-md text-center text-sm font-medium my-2 sm:w-auto sm:my-1" href="#0">Committed Amount: Kes {new Intl.NumberFormat().format(user_data?.commit)}</span>}

            <div className='w-full max-w-md flex justify-center gap-1 md:gap-4 py-8 px-4'>
                {tabs.map((tab, idx) =>
                    ((!user && (idx === -1 || idx === -1)) || user) && <button className={`${selected_tab === idx ? 'bg-red-900 text-white' : 'bg-white text-red-900 hover:bg-red-300'} flex-1 max-w-fit flex justify-center items-center gap-2 rounded border-1 px-3 py-2 md:py-2 md:px-4`}
                        onClick={() => {
                            setSelectedChat(-1)
                            setSelectedTab(idx)
                        }}><span className=' text-[10px] md:text-xs'>{tab}</span>
                    </button>
                )}
            </div>
            {true && <>
                {/* applicant list */}
                {/* {selected_tab === 0 && <>
                    <div className='flex justify-center items-center w-full max-w-md px-8'>
                        <span className=' font-semibold text-lg '>{`Looking to assist? Send an offer!`}</span>
                        <button className={`bg-white text-red-900 flex justify-center items-center gap-2 rounded border-1 px-2 py-1`}
                            onClick={() => setOffering(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                            <span className='text-xs'>Submit Your Offer</span>
                        </button>
                    </div>
                    <div className='w-full max-w-md px-2 py-4 flex flex-col gap-4'>
                        {
                            cvs.map((cv, idx) => <Applicants key={idx} applicant={cv} user_data={user_data} setSelectedChat={setSelectedChat} setSelectedTab={setSelectedTab} handleApplicants={handleCVs} />)
                        }
                    </div>
                </>} */}


                {/* applicant offers */}
                {selected_tab === 0 && <>
                    <div className='flex justify-between items-center w-full max-w-md px-8'>
                        <span className=' font-semibold text-lg '>{`Applicant Offers`}</span>

                        <button className={`bg-white text-red-900 flex justify-center items-center gap-2 rounded border-1 px-2 py-1`}
                            onClick={() => setOffering(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                            <span className='text-xs'>Post your offer</span>
                        </button>
                    </div>
                    <div className='w-full max-w-md px-2 py-4 flex flex-col gap-4'>
                        {
                            applicants.map((applicant, idx) => <ApplicantCard key={idx} applicant={applicant} user_data={user_data} setSelectedChat={setSelectedChat} setSelectedTab={setSelectedTab} handleApplicants={handleApplicants} />)
                        }
                    </div>
                </>}

                {/* assistants list */}
                {selected_tab === 1 && <>
                    <div className='flex justify-between items-center w-full max-w-md px-8'>
                        <span className=' font-semibold text-lg '>{`Assistant Offers`}</span>

                        <button className={`bg-white text-red-900 flex justify-center items-center gap-2 rounded border-1 px-2 py-1`}
                            onClick={() => setAdding(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                            <span className='text-xs'>Add Your Rate Card</span>
                        </button>
                    </div>
                    <div className='w-full max-w-md px-2 py-4 flex flex-col gap-4'>
                        {
                            assistants.map((assistant, idx) => <AssistantCard key={idx} assistant={assistant} cv={shares[0]} client={user_data} setSelectedTab={setSelectedTab} />)
                        }
                    </div>
                </>}

                {/*sent offers list */}
                {selected_tab === 2 && <>
                    <div className='flex justify-center items-center w-full max-w-md'>
                        <span className=' font-semibold text-lg '>{`Sent Application Offers`}</span>
                    </div>
                    <div className='w-full max-w-md px-2 py-4 flex flex-col gap-2'>
                        {sent_offers.map((offer, idx) => {
                            return (<OfferCard key={`c_${idx}`} tag={'client'} offer={offer} user_data={user_data} setSelectedChat={setSelectedChat} selected_chat={selected_chat} chat_index={idx} handleTopUp={setCreating} />)
                        })}
                    </div>
                </>}

                {/*received offers list */}
                {selected_tab === 3 && <>
                    <div className='flex justify-center items-center w-full max-w-md'>
                        <span className=' font-semibold text-lg '>{`Received Application Offers`}</span>
                    </div>
                    <div className='w-full max-w-md px-2 py-4 flex flex-col gap-2'>
                        {received_offers.map((offer, idx) => {
                            return (<OfferCard key={`v_${idx}`} tag={'vendor'} offer={offer} user_data={user_data} setSelectedChat={setSelectedChat} selected_chat={selected_chat} chat_index={idx} />)
                        })}
                    </div>
                </>}
            </>}


            {unlocking && <Transition appear show={unlocking !== null} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => { setUnlocking(null) }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white align-middle shadow-xl transition-all">
                                    {/* <Dialog.Title
                                        className="leading-6 text-sm text-gray-900 text-center bg-[#eee9dd80] p-6"
                                    > {`adding?.title`}</Dialog.Title> */}
                                    {
                                        //!user && 
                                        <div className="w-full flex flex-col  max-w-md transform overflow-hidden rounded-2xl align-middle shadow-xl transition-all">
                                            <div
                                                className="leading-6 text-sm text-gray-900 text-center bg-green-100 px-6 py-3"
                                            >
                                                {/* Login/Create your account */}
                                                {`Fundraiser target - Kes.${new Intl.NumberFormat().format(unlocking?.charge)}`}
                                                <br />
                                                {/* {!user && <span className="text-xs">Sign In to continue</span>} */}
                                            </div>
                                            {true ?
                                                <>
                                                    <div className='flex flex-col items-center space-y-4 justify-center px-4'>
                                                        <span className=' text-lg m-1 font-bold'>

                                                        </span>

                                                        <input placeholder='Who do I thank.. 😊'
                                                            type='number'
                                                            onChange={(e) => setAmount(parseInt(e.target.value))}
                                                            name="amount"
                                                            defaultValue={Math.min(amount, 1000)}
                                                            className={`p-4 text-center font-semibold relative w-full cursor-default overflow-hidden rounded-lg bg-white  shadow-md ring-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ${msisdn === '' ? 'ring-red-100 focus-visible:ring-offset-red-300' : 'ring-teal-100 focus-visible:ring-offset-teal-300'} sm:text-sm`}
                                                        />

                                                        <input placeholder='Mpesa Number'
                                                            type='tel'
                                                            onChange={(e) => handleMSISDNChange(e)}
                                                            name="msisdn"
                                                            defaultValue={`${msisdn}`}
                                                            className={`p-4 text-center font-semibold relative w-full cursor-default overflow-hidden rounded-lg bg-white  shadow-md ring-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ${msisdn === '' ? 'ring-red-100 focus-visible:ring-offset-red-300' : 'ring-teal-100 focus-visible:ring-offset-teal-300'} sm:text-sm`}
                                                        />
                                                    </div>

                                                    <div className="flex items-center justify-around m-4">
                                                        {(prompting) ?
                                                            (waiting) ?
                                                                <ReactLoading className="loading-anim" type="bubbles" color="green" />
                                                                :
                                                                <div className="sm:m-4 flex flex-col justify-center items-center">
                                                                    <span>{`welcome 🙂`}</span>
                                                                </div>
                                                            :
                                                            <>
                                                                <button
                                                                    disabled={msisdn === '' || !(amount > 0)}
                                                                    className="inline-flex justify-center rounded-md border border-transparent disabled:text-gray-900 bg-green-200 px-4 py-2 text-sm font-medium disabled:bg-gray-300 text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                                                    onClick={handleUnlock}
                                                                >Prompt</button>
                                                            </>
                                                        }
                                                    </div>
                                                </>
                                                :
                                                <button
                                                    className="inline-flex m-6 justify-center rounded-md border border-transparent bg-blue-200 px-4 py-2 text-sm font-medium disabled:bg-gray-300 text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                                    onClick={() => { signInWithGoogle() }}
                                                >{period.min === 0 ? 'Get Started! Sign In' : 'Sign in'}</button>
                                            }
                                        </div>
                                    }

                                </Dialog.Panel>

                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>}

            {<Transition appear show={creating} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => { setCreating(false) }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white align-middle shadow-xl transition-all">
                                    {/* <Dialog.Title
                                        className="leading-6 text-sm text-gray-900 text-center bg-[#eee9dd80] p-6"
                                    > {`adding?.title`}</Dialog.Title> */}
                                    {
                                        user && shares.length > 0
                                            ?
                                            <div className="w-full flex flex-col  max-w-md transform overflow-hidden rounded-2xl align-middle shadow-xl transition-all">
                                                <div
                                                    className="leading-6 text-sm text-gray-900 text-center bg-green-100 px-6 py-3"
                                                >
                                                    {/* Login/Create your account */}
                                                    You are investing in yourself - <b>Almost there!</b>
                                                    <br />
                                                    {/* <span className="text-xs">No delivery fees</span> */}
                                                </div>
                                                <div className='flex flex-col items-center justify-center py-4 px-8 space-y-4'>

                                                    <input placeholder='Enter Top up Amount'
                                                        type='number'
                                                        onChange={(e) => {

                                                            const input = e.target.value !== '' ? parseInt(e.target.value) : 0
                                                            setAmount(input)

                                                        }}
                                                        name="amount"
                                                        className={`p-4 text-center font-semibold relative w-full cursor-default overflow-hidden rounded-lg bg-white  shadow-md ring-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ${jobs === 0 ? 'ring-red-100 focus-visible:ring-offset-red-300' : 'ring-teal-100 focus-visible:ring-offset-teal-300'} sm:text-sm`}
                                                    />
                                                </div>

                                                {user ?
                                                    <>
                                                        <div className='flex flex-col items-center justify-center px-4'>
                                                            <span className=' text-lg m-1 font-bold'>

                                                            </span>

                                                            {/* {msisdn !== '' && <label className="mt-4 mb-1 text-xs" >{"Confirm your number"}</label>} */}
                                                            <input placeholder='Mpesa Number'
                                                                type='tel'
                                                                onChange={(e) => handleMSISDNChange(e)}
                                                                name="msisdn"
                                                                defaultValue={`${msisdn}`}
                                                                className={`p-4 text-center font-semibold relative w-full cursor-default overflow-hidden rounded-lg bg-white  shadow-md ring-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ${msisdn === '' ? 'ring-red-100 focus-visible:ring-offset-red-300' : 'ring-teal-100 focus-visible:ring-offset-teal-300'} sm:text-sm`}
                                                            />
                                                        </div>

                                                        <div className="flex items-center justify-around m-4">
                                                            {(prompting) ?
                                                                (waiting) ?
                                                                    <ReactLoading className="loading-anim" type="bubbles" color="green" />
                                                                    :
                                                                    <div className="sm:m-4 flex flex-col justify-center items-center">
                                                                        <span>{`welcome 🙂`}</span>
                                                                    </div>
                                                                :
                                                                <>
                                                                    <button
                                                                        disabled={!((value?.data()?.["fee"] !== null) && msisdn !== '')}
                                                                        className="inline-flex justify-center rounded-md border border-transparent disabled:text-gray-900 bg-green-200 px-4 py-2 text-sm font-medium disabled:bg-gray-300 text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                                                        onClick={() => {
                                                                            SendPayload({
                                                                                amount: amount,
                                                                                msisdn: msisdn,
                                                                                account_id: user?.uid
                                                                            }, { setPrompting, setWaiting })
                                                                        }}
                                                                    >Prompt</button>
                                                                </>
                                                            }
                                                        </div>
                                                    </>
                                                    :
                                                    <button
                                                        className="inline-flex m-6 justify-center rounded-md border border-transparent bg-blue-200 px-4 py-2 text-sm font-medium disabled:bg-gray-300 text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                                        onClick={() => { signInWithGoogle() }}
                                                    >{period.min === 0 ? 'Get Started! Sign In' : 'Sign in to subscribe'}</button>
                                                }
                                            </div>
                                            :
                                            <div className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                                <p
                                                    as="h3"
                                                    className="text-lg font-medium leading-6 text-gray-900"
                                                >
                                                    Upload Your CV
                                                </p>

                                                <div className='flex flex-col gap-2 py-4'>
                                                    <div className='w-full flex flex-col py-2 justify-center items-center border-green-400 border-dashed border-2'>
                                                        <div className=' flex justify-around w-full'>
                                                            {files.length > 0 && files.map((file, idx) => {
                                                                const image = URL.createObjectURL(file)
                                                                return (
                                                                    <div key={idx} className='flex-1 max-w-[50%] flex flex-col items-center space-y-2 my-2'>
                                                                        <span className='text-[8px] text-ellipsis'>{file.name}</span>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                                                        </svg>


                                                                        <button onClick={() => {
                                                                            const remFiles = files.filter((f) => f !== file)
                                                                            setFiles([...remFiles])
                                                                        }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                                                                                <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM8.28 7.22a.75.75 0 0 0-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 1 0 1.06 1.06L10 11.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L11.06 10l1.72-1.72a.75.75 0 0 0-1.06-1.06L10 8.94 8.28 7.22Z" clip-rule="evenodd" />
                                                                            </svg>

                                                                        </button>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>

                                                        {files.length < 2 && <button onClick={() => { fileInputRef.current.click() }}
                                                            className='flex gap-2 justify-center items-center bg-green-500 hover:bg-green-600 text-white text-xs py-2 px-4 rounded-lg shadow-md'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                                            </svg>
                                                            <span className=' font-medium'>Upload CV & Sample Cover Letter</span>
                                                        </button>}

                                                        <input className='bg-white hidden' type="file" accept=".doc, .docx, .pdf" id="avatar" name="avatar"
                                                            ref={fileInputRef}
                                                            max={2}
                                                            onChange={handleFileChange} multiple={true} />
                                                    </div>
                                                    <input placeholder='Name' type='text' onChange={(e) => handleNameChange(e)}
                                                        className=" p-4 relative flex w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />

                                                    <input placeholder='Email' type='text' onChange={(e) => handleCaptionChange(e)}
                                                        className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />

                                                    <input placeholder='LinkedIn/Github/Portfolio Link' defaultValue={link} type='text' onChange={(e) => setLink(e.target.value)}
                                                        className="my-2 p-4 relative flex w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />

                                                    <textarea placeholder='Describe the kind of job position(s) you are looking for. (in your own words)' type='text' onChange={(e) => handleDescChange(e)}
                                                        className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />


                                                </div>

                                                {
                                                    uploading ?
                                                        <ReactLoading type="bubbles" color="black" />

                                                        :

                                                        <div className='w-full flex justify-around items-center gap-4'>
                                                            <button
                                                                type="button"
                                                                className="flex-1 inline-flex justify-center rounded-md border disabled:bg-gray-300 disabled:text-gray-900 border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                                                onClick={() => setCreating(false)}
                                                            >
                                                                Close
                                                            </button>

                                                            {user ? <button
                                                                disabled={
                                                                    (!free && (!user_data?.active ?? true)) ||
                                                                    name === ''
                                                                    || files.length === 0
                                                                    || desc === ''
                                                                    || (lock && charge <= 0)
                                                                    // || link === ''
                                                                }
                                                                type="button"
                                                                className="flex-1 inline-flex justify-center rounded-md border disabled:bg-gray-300 disabled:text-gray-900 border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                                                onClick={handleUpload}
                                                            >
                                                                Save Info
                                                            </button> :
                                                                <button
                                                                    className="flex-1  inline-flex justify-center rounded-md border border-transparent bg-blue-200 px-4 py-2 text-sm font-medium disabled:bg-gray-300 text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                                    onClick={() => { signInWithGoogle() }}
                                                                >{'Sign In'}</button>
                                                            }
                                                        </div>
                                                }
                                            </div>

                                    }

                                </Dialog.Panel>

                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>}

            {<Transition appear show={adding !== null} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => { }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        className="leading-6 text-sm text-gray-900 text-center bg-[#eee9dd80] p-6"
                                    > {`Hi there, start earning with ApplymyCV!`}</Dialog.Title>

                                    <div className=' max-h-[70vh] overflow-auto px-4 bg-white py-8 flex flex-col gap-4'>

                                        <input placeholder='Name' type='text' defaultValue={user_data?.name} onChange={(e) => handleNameChange(e)}
                                            className=" p-4 relative flex w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />
                                        <input placeholder='Phone Number'
                                            type='tel'
                                            onChange={(e) => handleMSISDNChange(e)}
                                            name="msisdn"
                                            defaultValue={`${msisdn}`}
                                            className={`p-4 text-start font-semibold relative w-full cursor-default overflow-hidden rounded-lg bg-white  shadow-md ring-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ${msisdn === '' ? 'ring-red-100 focus-visible:ring-offset-red-300' : 'ring-teal-100 focus-visible:ring-offset-teal-300'} sm:text-sm`}
                                        />

                                        <input placeholder='Charge per Application in Kes.' type='number' onChange={(e) => handleAmountChange(e)}
                                            className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />

                                        <textarea placeholder='Sell yourself to potential clients on why you are best suited to handle job applications on their behalf. (in your own words)' type='text' onChange={(e) => handleDescChange(e)}
                                            className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />


                                    </div>
                                    {uploading ? <div className='flex justify-center items-center'>
                                        <ReactLoading type="bubbles" color="black" />
                                    </div>

                                        : <div className='p-4 flex justify-around items-center'>

                                            <button
                                                onClick={closeAddModal}
                                                className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                            >Close</button>

                                            {user ?
                                                <button
                                                    disabled={desc === '' || msisdn === '' || name === ''}
                                                    onClick={handleAdd}
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                                >
                                                    Create
                                                </button> :
                                                <button
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-200 px-4 py-2 text-sm font-medium disabled:bg-gray-300 text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                    onClick={() => { signInWithGoogle() }}
                                                >{'Sign In'}</button>
                                            }
                                        </div>}
                                </Dialog.Panel>

                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>}

            {<Transition appear show={offering} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        className="leading-6 text-sm text-gray-900 text-center bg-[#eee9dd80] p-6 flex flex-col justify-center items-center"
                                    >
                                        <span>{`Offer from ${user_data?.name}`}</span>
                                        <span>{`Rate: Kes.${new Intl.NumberFormat().format(rate)} per Application`}</span>
                                        <span>{`Offer Total: Kes.${new Intl.NumberFormat().format(total)}`}</span>
                                    </Dialog.Title>

                                    {shares?.length > 0 && <div className=' max-h-[60vh] overflow-auto px-4 bg-white py-8 flex flex-col gap-4'>

                                        <input placeholder='Pay per Application in Kes.' type='number' onChange={(e) => handleRateChange(e)}
                                            className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />

                                        <input placeholder='Desired number of job applications' type='number' onChange={(e) => handleJobsChange(e)}
                                            className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />
                                        {topup && <span className='text-red-800 font-semibold text-xs'>{'This offer exceeds your Account Balance. Kindly Top up'}</span>}

                                        {!topup && <textarea className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm"
                                            placeholder='Assistant expectations..' type='text' onChange={(e) => handleDescChange(e)} />}
                                    </div>}
                                    {!(shares?.length > 0) ?
                                        <CVUpload user_data={user_data} close={closeModal} />
                                        : topup ?
                                            <TopUpCard user_data={user_data} initial_amount={total} />
                                            :
                                            uploading ?
                                                <div className='flex justify-center items-center'>
                                                    <ReactLoading type="bubbles" color="black" />
                                                </div>
                                                : <div className='p-4 flex justify-around items-center'>

                                                    <button
                                                        onClick={closeModal}
                                                        className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                                    >Close</button>

                                                    {user_data ?
                                                        <button
                                                            disabled={topup || total === 0}
                                                            onClick={handleOffer}
                                                            className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-green-900 bg-green-100 hover:bg-green-200 disabled:text-gray-900 disabled:bg-gray-100 disabled:hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                                        >
                                                            Submit Offer
                                                        </button> :
                                                        <button
                                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-200 px-4 py-2 text-sm font-medium disabled:bg-gray-300 text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                            onClick={() => { signInWithGoogle() }}
                                                        >{'Sign In'}</button>
                                                    }
                                                </div>
                                    }
                                </Dialog.Panel>

                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>}

            {frame && <Transition appear show={frame !== null} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeFrameModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        className="leading-6 text-sm text-gray-900 text-center bg-[#eee9dd80] p-6"
                                    > {frame?.caption}</Dialog.Title>

                                    <div className='flex flex-col justify-around items-center'>

                                        <img
                                            className={`${shared.lock && !(user_data?.purchases ?? []).includes(shared.id) && !frame?.public ? 'blur-2kxl' : ''}  bg-white h-auto max-h-[60vh] my-0 object-center object-contain w-full`}
                                            src={frame?.url}
                                            alt=""
                                            loading="lazy"
                                        />
                                        <a href={frame?.link} target='_blank'
                                            className="flex my-4 justify-between items-center space-x-4 rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                            rel="noreferrer"
                                        >

                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-3 h-3">
                                                <path d="M12.232 4.232a2.5 2.5 0 0 1 3.536 3.536l-1.225 1.224a.75.75 0 0 0 1.061 1.06l1.224-1.224a4 4 0 0 0-5.656-5.656l-3 3a4 4 0 0 0 .225 5.865.75.75 0 0 0 .977-1.138 2.5 2.5 0 0 1-.142-3.667l3-3Z" />
                                                <path d="M11.603 7.963a.75.75 0 0 0-.977 1.138 2.5 2.5 0 0 1 .142 3.667l-3 3a2.5 2.5 0 0 1-3.536-3.536l1.225-1.224a.75.75 0 0 0-1.061-1.06l-1.224 1.224a4 4 0 1 0 5.656 5.656l3-3a4 4 0 0 0-.225-5.865Z" />
                                            </svg>
                                            <span>visit link</span>
                                        </a>
                                    </div>
                                </Dialog.Panel>

                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>}
        </div>
    )
}

export default WhatsappEverClear