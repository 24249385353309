import React, { useRef, useState } from 'react'
import ReactLoading from 'react-loading';
import { captureResume, signInWithGoogle } from '../firebaseConfig';

const CVUpload = ({user_data, close}) => {

    const fileInputRef = useRef(null)
    const [link, setLink] = useState(null)
    const [creating, setCreating] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [shareLoaded, setShareLoaded] = useState(false)
    const [name, setName] = useState('')
    const [caption, setCaption] = useState('')
    const [desc, setDesc] = useState('')
    const [files, setFiles] = useState([])

    const closeListing = () => {
        setUploading(false)
        setFiles([])
    }
    const handleNameChange = (event) => {
        setName(event.target.value);
    }
    const handleDescChange = (event) => {
        setDesc(event.target.value);
    }
    const handleCaptionChange = (event) => {
        setCaption(event.target.value);
    }
    const handleFileChange = (event) => {
        const newFiles = event.target.files;
        setFiles([...files, ...newFiles]);
    }
    const closeModal = () => {
        setCreating(false)
        setFiles([])
        setUploading(false)
        setLink(null)
        setName('')
        setCaption('')
        setDesc('')
    }
    
    const handleUpload = async () => {
        setUploading(true)

        if (files.length > 0) {
            const data = {
                hits: 0,
                title: name,
                uid: user_data?.uid ?? '',
                caption: caption,
                phone: user_data?.phone ?? '',
                description: desc,
                dominantColor: '',
                adult: false,
                lock: false,
                link: link,
                hidden: false,
                charge: 0
            }
            await captureResume(files, data, closeModal, {
                public: true,
            })
        } else {
            setUploading(false)
            console.log('no file')
        }
    }
  return (
    <div className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
        <p
            as="h3"
            className="text-lg font-medium leading-6 text-gray-900"
        >
            Upload Your Info
        </p>

        <div className='flex flex-col gap-2 py-4'>
            <div className='w-full flex flex-col py-2 justify-center items-center border-green-400 border-dashed border-2'>
                <div className=' flex justify-around w-full'>
                    {files.length > 0 && files.map((file, idx) => {
                        const image = URL.createObjectURL(file)
                        return (
                            <div key={idx} className='flex-1 max-w-[50%] flex flex-col items-center space-y-2 my-2'>
                                <span className='text-[8px] text-ellipsis'>{file.name}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                </svg>


                                <button onClick={() => {
                                    const remFiles = files.filter((f) => f !== file)
                                    setFiles([...remFiles])
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                                        <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM8.28 7.22a.75.75 0 0 0-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 1 0 1.06 1.06L10 11.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L11.06 10l1.72-1.72a.75.75 0 0 0-1.06-1.06L10 8.94 8.28 7.22Z" clip-rule="evenodd" />
                                    </svg>

                                </button>
                            </div>
                        )
                    })}
                </div>

                {files.length < 2 && <button onClick={() => { fileInputRef.current.click() }}
                    className='flex gap-2 justify-center items-center bg-green-500 hover:bg-green-600 text-white text-xs py-2 px-4 rounded-lg shadow-md'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                    </svg>
                    <span className=' font-medium'>Upload CV & Sample Cover Letter</span>
                </button>}

                <input className='bg-white hidden' type="file" accept=".doc, .docx, .pdf" id="avatar" name="avatar"
                    ref={fileInputRef}
                    max={2}
                    onChange={handleFileChange} multiple={true} />
            </div>
            <input placeholder='Name' type='text' onChange={(e) => handleNameChange(e)}
                className=" p-4 relative flex w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />

            <input placeholder='Email' type='text' onChange={(e) => handleCaptionChange(e)}
                className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />

            <input placeholder='LinkedIn/Github/Portfolio Link' defaultValue={link} type='text' onChange={(e) => setLink(e.target.value)}
                className="my-2 p-4 relative flex w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />

            <textarea placeholder='Describe the kind of job position(s) you are looking for. (in your own words)' type='text' onChange={(e) => handleDescChange(e)}
                className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />


        </div>

        {
            uploading ?
                <ReactLoading type="bubbles" color="black" />

                :

                <div className='w-full flex justify-around items-center gap-4'>
                    <button
                        type="button"
                        className="flex-1 inline-flex justify-center rounded-md border disabled:bg-gray-300 disabled:text-gray-900 border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                        onClick={close}
                    >
                        Close
                    </button>

                    {user_data ? <button
                        disabled={
                            name === ''
                            || files.length === 0
                            || desc === ''
                        }
                        type="button"
                        className="flex-1 inline-flex justify-center rounded-md border disabled:bg-gray-300 disabled:text-gray-900 border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                        onClick={handleUpload}
                    >
                        Save Info
                    </button> :
                        <button
                            className="flex-1  inline-flex justify-center rounded-md border border-transparent bg-blue-200 px-4 py-2 text-sm font-medium disabled:bg-gray-300 text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                            onClick={() => { signInWithGoogle() }}
                        >{'Sign In'}</button>
                    }
                </div>
        }
    </div>
  )
}

export default CVUpload