import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from 'firebase/app'
import React, { useState, useEffect, useRef } from 'react';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut, OAuthProvider, signInAnonymously, onAuthStateChanged } from 'firebase/auth'
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { collection, query, where, getFirestore, doc, onSnapshot, setDoc, getDoc, getDocs, Timestamp, updateDoc, orderBy, limit, addDoc, startAt, endAt, increment, arrayUnion, arrayRemove } from 'firebase/firestore';
import { useCollection, useDocument, useCollectionData } from 'react-firebase-hooks/firestore';
import dayjs from "dayjs";
import { geohashQueryBounds, distanceBetween, geohashForLocation } from 'geofire-common'
import { addDays, getMoment, shorten, sleep } from "./helpers";
import { now } from "moment";


const radiusInM = 300000
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDCuwWHLDk-BKDMUFFGRVQgtagDDEbbWjw",
    authDomain: "autosoft-614e7.firebaseapp.com",
    projectId: "autosoft-614e7",
    storageBucket: "autosoft-614e7.appspot.com",
    messagingSenderId: "860007949461",
    appId: "1:860007949461:web:cc1d25273ed7b783f1a79c",
    measurementId: "G-RDEYD6BDCJ"
};

export const COLLECTIONS = {
    REQUESTS: "Requests",
    PRODUCTS: "Products",
    PHONES: "Phones",
    ACCOUNTS: "Accounts",
    MENUS: "Menus",
    CONSTS: "Constants",
    INVOICES: "Invoices",
    PRELOVED: "Preloved",
    SHARED: "Shared",
    USERS: "Users",
    ASSISTANTS: "Assistants",
    APPLICANTS: "Applicants",
    CV: "CV",
    MAIL: "mail",
    OFFERS: "Offers",
}

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
const storage = getStorage(app);


export const template = (name, activity) => {
    const logo = 'https://firebasestorage.googleapis.com/v0/b/autosoft-614e7.appspot.com/o/applymycv%20logo.png?alt=media&token=1ffb2043-ef47-44c8-8315-fc380d70ad8c'
    return `<html lang="en">
            <head>
                <title>APPLY MY CV</title>
            </head>
            <body style="font-family: Arial, sans-serif; margin: 0; padding: 0; background-color: #f5f5f5;">
                <div class="container" style="max-width: 600px; margin: 50px auto; padding: 30px; background-color: #fff; border-radius: 5px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);">
                    <div class="header" style="border-bottom: 1px solid #ddd; padding-bottom: 15px; margin-bottom: 15px;">
                        <img src="${logo}" alt="ApplymyCV Logo" style="max-width: 150px; height: auto;" />
                    </div>
                    <div class="content" style="line-height: 1.5; color: #333;">
                        <p>Hi ${name},</p>
                        <p>${activity}</p>
                        <p>Use the link below to view</p>
                    </div>
                    <div class="button-container" style="text-align: center;">
                        <a href=${'https://applymycv.com'} style="display: inline-block; padding: 10px 20px; font-size: 16px; text-decoration: none; color: #fff; background-color: #4CAF50; border: none; border-radius: 5px; cursor: pointer;" class="button">
                            View on ApplymyCV
                        </a>
                    </div>
                    <div class="footer" style="text-align: center; font-size: 12px; color: #aaa; margin-top: 20px;">
                        <p>Thanks,</p>
                        <p>ApplymyCV</p>
                    </div>
                </div>
            </body>
        </html>`
}

export const sendMail = async (to, subject, html, uid) => {
    const data = {
        sent: Timestamp.fromDate(new Date()),
        uid: uid,
        to: [to],
        message: {
            subject: subject,
            html: html
        }
    }
    const mail = await addDoc(collection(db, COLLECTIONS.MAIL), data)
    console.log('mail id', mail.id)
}

export function SignIn() {
    return (
        <button onClick={signInWithMicrosoft}
            style={{ borderRadius: '8', margin: '10px', width: '15vw' }}
        >
            Sign In
        </button>
    )
}

onAuthStateChanged(auth, async (user) => {
    if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        const uid = user.uid;
        // ...
    } else {
        // User is signed out
        // ...
    }
});
export const anon = (cb) => {

    signInAnonymously(auth)
        .then(async (r) => {
            console.log(' Signed in..', r)
            const user = r.user

            const user_doc = doc(db, COLLECTIONS.USERS, user.uid);
            const docSnap = await getDoc(user_doc);

            if (docSnap.exists()) {
                // doc.data() will be undefined in this case
                console.log("User exists!");
            } else {
                // save user
                const docData = {
                    created: Timestamp.fromDate(new Date()),
                    uid: user.uid,
                    name: 'anon',
                    email: '',
                    photo: '',
                    isVendor: false,
                    active: false,
                    about: ''
                };
                await setDoc(user_doc, docData)
            }
            cb(null, user)
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorMessage)
        });
}

export function SignOut() {
    return (
        <button onClick={logout}>Sign out</button>
    );
}

export const signInWithMicrosoft = async (callback) => {
    const provider = new OAuthProvider('microsoft.com')
    signInWithPopup(auth, provider)
        .then((result) => {
            // User is signed in.
            // IdP data available in result.additionalUserInfo.profile.

            // Get the OAuth access token and ID Token
            const credential = OAuthProvider.credentialFromResult(result);
            const accessToken = credential.accessToken;
            const idToken = credential.idToken;
            console.log('cred', credential)
        })
        .catch((error) => {
            // Handle error.
            console.log('err', error)
        });
}

export const signInWithGoogle = async (callback) => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
        .then(async (result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            //check if user exists

            const user_doc = doc(db, COLLECTIONS.USERS, user.uid);
            const docSnap = await getDoc(user_doc);

            if (docSnap.exists()) {
                await updateDoc(user_doc, { token: token })
                // doc.data() will be undefined in this case
                console.log("User exists!");
            } else {
                // save user
                const docData = {
                    created: Timestamp.fromDate(new Date()),
                    uid: user.uid,
                    name: user.displayName,
                    email: user.email,
                    token: token,
                    photo: user.photoURL,
                    isVendor: false,
                    active: false,
                    about: ""
                };

                await setDoc(user_doc, docData)


            }
            callback && callback()
        }).catch((error) => {
            console.log(error);
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
            callback && callback()
        });
}

export const logout = () => {
    signOut(auth).then(() => {
        // Sign-out successful.
    }).catch((error) => {
        // An error happened.
    });
}


export const getUser = async (id, setUser) => {
    if (id) {
        const ref = doc(db, COLLECTIONS.USERS, id)
        const d = await getDoc(ref)
        const unsub = onSnapshot(
            ref,
            { includeMetadataChanges: true },
            (d) => {
                const data = {
                    uid: d.id,
                    name: d.data()?.name ?? '',
                    email: d.data()?.email ?? '',
                    phone: d.data()?.phone ?? '',
                    image: d.data()?.image ?? '',
                    hero: d.data()?.hero ?? '',
                    isVendor: d.data()?.isVendor,
                    active: d.data()?.active ?? false,
                    purchases: d.data()?.purchases ?? [],
                    debit: d.data()?.debit ?? 0,
                    credit: d.data()?.credit ?? 0,
                    commit: d.data()?.commit ?? 0,
                    jobs: d.data()?.jobs ?? 0
                }
                setUser(data)
            });
        return unsub
    } else { setUser(null) }
    return null
}

export const getCV = async (id) => {
    if (id) {
        const ref = doc(db, COLLECTIONS.CV, id)
        const d = await getDoc(ref)

        // console.log(id,d.data())
        if (d.exists()) {

            const data = {
                id: d.id,
                title: d.data().title,
                hits: d.data().hits,
                files: d.data().files,
                uid: d.data().uid,
                menuid: d.data().menuid,
                phone: d.data().phone ?? '',
                description: d.data().description,
                caption: d.data().caption,
                dominantColor: d.data().dominantColor,
                lock: d.data().lock ?? false,
                hidden: d.data().hidden ?? false,
                credit: d.data().credit ?? 0,
                charge: d.data().charge ?? 0,
            }
            return data
        }
    }
    return null
}
export const getCVs = async () => {

    const q = query(
        collection(db, COLLECTIONS.CV),
        orderBy('created', 'desc'),
        limit(100)
    )
    const querySnapshot = await getDocs(q)

    if (!querySnapshot.empty) {
        const data = []
        querySnapshot.forEach((d) => {
            const created = d.data().created
            const date = created.toDate()
            const shortdate = date.toLocaleDateString("en-GB")
            data.push({
                id: d.id,
                title: d.data().title,
                hits: d.data().hits,
                files: d.data().files,
                uid: d.data().uid,
                menuid: d.data().menuid,
                phone: d.data().phone ?? '',
                claims: d.data().claims ?? [],
                description: d.data().description,
                caption: d.data().caption,
                dominantColor: d.data().dominantColor,
                lock: d.data().lock ?? false,
                hidden: d.data().hidden ?? false,
                credit: d.data().credit ?? 0,
                charge: d.data().charge ?? 0,
                date: shortdate
            })
        })
        return data
    } else {
        return []
    }
}
export const updateCV = async (id, vendoruid) => {

    const update = {
        claims: arrayUnion(vendoruid)
    }
    await updateDoc(doc(db, COLLECTIONS.CV, id), update)
}

export const addToApplicants = async (data, callback) => {
    // save user
    const docData = {
        created: Timestamp.fromDate(new Date()),
        ...data
    }
    const col = collection(db, COLLECTIONS.APPLICANTS)
    await addDoc(col, docData)
    callback()
}
export const getApplicants = async () => {
    // console.log(uid)

    const q = query(
        collection(db, COLLECTIONS.APPLICANTS),
        orderBy('created', 'desc'),
        limit(100)
    )
    const querySnapshot = await getDocs(q)

    if (!querySnapshot.empty) {
        const data = []
        querySnapshot.forEach((doc) => {
            const created = doc.data().created
            const date = created.toDate()
            const shortdate = date.toLocaleDateString("en-GB")
            data.push({
                id: doc.id,
                uid: doc.data().uid,
                name: doc.data().name,
                email: doc.data().email,
                rate: doc.data().rate,
                jobs: doc.data().jobs,
                total: doc.data().total,
                description: doc.data().description,
                image: doc.data().image,
                hero: doc.data().hero,
                claims: doc.data().claims,
                clientid: doc.data().clientid,
                expectations: doc.data().expectations,
                date: shortdate
            })
        })
        return data
    } else {
        return []
    }

}
export const updateApplicant = async (id, vendoruid) => {

    const update = {
        claims: arrayUnion(vendoruid)
    }

    await updateDoc(doc(db, COLLECTIONS.APPLICANTS, id), update)
}

export const addToAssistants = async (data, callback) => {
    // save user
    const docData = {
        created: Timestamp.fromDate(new Date()),
        ...data
    }
    const col = collection(db, COLLECTIONS.ASSISTANTS)
    await addDoc(col, docData)
    callback?.()
}
export const getAssistants = async () => {
    // console.log(uid)

    const q = query(
        collection(db, COLLECTIONS.ASSISTANTS),
        orderBy('created', 'desc'),
        limit(100)
    )
    const querySnapshot = await getDocs(q)

    if (!querySnapshot.empty) {
        const data = []
        querySnapshot.forEach((doc) => {
            const created = doc.data().created
            const date = created.toDate()
            const shortdate = date.toLocaleDateString("en-GB")
            data.push({
                id: doc.id,
                uid: doc.data().uid,
                name: doc.data().name,
                email: doc.data().email,
                rate: doc.data().rate,
                desc: doc.data().desc,
                ratings: doc.data().ratings,
                image: doc.data().image,
                hero: doc.data().hero,
                date: shortdate
            })
        })
        return data
    } else {
        return []
    }

}

export const addToOffers = async (data, callback) => {
    // save user
    const docData = {
        created: Timestamp.fromDate(new Date()),
        ...data
    }
    const col = collection(db, COLLECTIONS.OFFERS)
    const offer = await addDoc(col, docData)
    callback(offer)
}
export const updateOffer = async (id, data) => {

    await updateDoc(doc(db, COLLECTIONS.OFFERS, id), data)
}
export const updateOfferChat = async (id, chat) => {

    const update = {
        chat: arrayUnion({
            created: Timestamp.fromDate(new Date()),
            ...chat
        })
    }

    await updateDoc(doc(db, COLLECTIONS.OFFERS, id), update)
}

const parseOfferData = (doc) => {
    const created = doc.data().created
    const date = created.toDate()
    const shortdate = date.toLocaleDateString("en-GB")
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    const formattedTime = `${hours}:${minutes}:${seconds}`

    return {
        id: doc.id,
        vendorid: doc.data().vendorid,
        clientid: doc.data().clientid,
        vendoruid: doc.data().vendoruid,
        clientuid: doc.data().clientuid,
        vendorname: doc.data()?.vendorname ?? 'Anon',
        clientname: doc.data()?.clientname ?? 'Anon',
        vendoremail: doc.data()?.vendoremail ?? '',
        clientemail: doc.data()?.clientemail ?? '',
        chat: doc.data().chat,
        jobs: doc.data().jobs ?? 0,
        total: doc.data().total,
        rate: doc.data.rate ?? 0,
        status: doc.data().status,
        rating: doc.data().rating,
        desc: doc.data().desc,
        committed: doc.data().committed??false,
        shareddetails: doc.data().shareddetails ?? false,
        vendoraccepted: doc.data().vendoraccepted ?? false,
        payrequested: doc.data().payrequested ?? false,
        vendorpaid: doc.data().vendorpaid ?? false,
        closed: doc.data().closed ?? false,
        canceled: doc.data().canceled ?? false,
        date: `${shortdate} ${formattedTime}`
    }

}
export const getSentOffers = async (uid, setItems) => {

    if (uid) {

        const q = query(
            collection(db, COLLECTIONS.OFFERS),
            where('clientuid', '==', uid),
            orderBy('created', 'desc'),
            limit(100)
        )

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            if (!querySnapshot.empty) {
                const data = []
                querySnapshot.forEach((doc) => {
                    // console.log(doc.data())
                    data.push(parseOfferData(doc))
                })
                // console.log(data)
                setItems(data)
            } else { console.log('nothing yet') }

        });
        return unsubscribe
    }

}
export const getReceivedOffers = async (uid, setItems) => {

    if (uid) {

        const q = query(
            collection(db, COLLECTIONS.OFFERS),
            where('vendoruid', '==', uid),
            orderBy('created', 'desc'),
            limit(100)
        )

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            if (!querySnapshot.empty) {
                const data = []
                querySnapshot.forEach((doc) => {
                    // console.log(doc.data())
                    data.push(parseOfferData(doc))
                })
                // console.log(data)
                setItems(data)
            } else { console.log('nothing yet') }

        });
        return unsubscribe
    }

}

export const updateUserBalances = async (cid, vid, amount) => {
    const c = doc(db, COLLECTIONS.USERS, cid)
    await setDoc(c, { commit: increment(-amount) }, { merge: true })
    const v = doc(db, COLLECTIONS.USERS, vid)
    await setDoc(v, { credit: increment(amount) }, { merge: true })
}
export const commitUserBalance = async (uid, amount) => {
    const c = doc(db, COLLECTIONS.USERS, uid)
    await setDoc(c, { commit: increment(amount), debit: increment(-amount) }, { merge: true })
}
export const remmitUserBalance = async (uid, amount) => {
    const c = doc(db, COLLECTIONS.USERS, uid)
    await setDoc(c, { commit: increment(-amount), debit: increment(amount) }, { merge: true })
}

export const updateUser = async (id, data) => {
    const col = doc(db, COLLECTIONS.USERS, id)
    await setDoc(col, { phone: data.phone }, { merge: true })
}

export const getMenu = async (id, pos) => {
    const ref = doc(db, COLLECTIONS.MENUS, id)
    const d = await getDoc(ref)
    if (d.exists()) {
        console.log("le data", d.data())


        const created = d.data().created
        const date = created.toDate()
        const shortdate = date.toLocaleDateString("en-GB")
        const lat = d.data().lat
        const lng = d.data().lng

        // We have to filter out a few false positives due to GeoHash
        // accuracy, but most will match
        const distanceInKm = pos ? distanceBetween([lat, lng], [pos.lat, pos.lng]) : 0

        const data = {
            id: d.id,
            title: d.data().title,
            image: d.data().image,
            desc: d.data().desc,
            phone: d.data().phone,
            limit: d.data().limit,
            requests: d.data().requests,
            prompt: d.data().prompt,
            sales: d.data().sales,
            lat: d.data().lat,
            lng: d.data().lng,
            bonus: d.data().bonus,
            balance: d.data().balance,
            active: d.data().active,
            editor: auth.currentUser ? auth.currentUser.uid === d.data().uid : false,
            dist: distanceInKm,
            within: d.data().limit >= distanceInKm,
            likes: d.data().likes,
            date: shortdate,
        }

        if (pos) {
            const center = [pos.lat, pos.lng];
            const lat = d.data().lat
            const lng = d.data().lng

            const distanceInKm = distanceBetween([lat, lng], center);

            return {
                ...data,
                dist: distanceInKm,
                within: d.data().limit >= distanceInKm,
            }
        }
        return data
    } else { return null }
}
export const getUserFeed = async (setItems) => {
    // console.log(uid)

    const q = query(
        collection(db, COLLECTIONS.SHARED),
        where('hidden', '==', false),
        orderBy('created', 'desc'),
        limit(100)
    )
    const querySnapshot = await getDocs(q)

    if (!querySnapshot.empty) {
        const data = []
        querySnapshot.forEach((doc) => {
            const created = doc.data().created
            const date = created.toDate()
            const shortdate = date.toLocaleDateString("en-GB")
            data.push({
                id: doc.id,
                title: doc.data().title,
                hits: doc.data().hits,
                images: doc.data().images,
                uid: doc.data().uid,
                menuid: doc.data().menuid,
                phone: doc.data().phone ?? '',
                description: doc.data().description,
                caption: doc.data().caption,
                dominantColor: doc.data().dominantColor,
                lock: doc.data().lock ?? false,
                hidden: doc.data().hidden ?? false,
                date: shortdate,
                credit: doc.data().credit ?? 0,
                charge: doc.data().charge ?? 0,
            })
        })
        setItems(data)

    }

}

export const getUserMenus = async (uid, setItems) => {

    const data = []

    const q = query(
        collection(db, COLLECTIONS.MENUS),
        where('uid', '==', uid),
        orderBy('created', 'desc')
    )

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
            const created = doc.data().created
            const date = created.toDate()
            const shortdate = date.toLocaleDateString("en-GB")
            data.push({
                id: doc.id,
                active: doc.data().active,
                title: doc.data().title,
                image: doc.data().image,
                desc: doc.data().desc,
                limit: doc.data().limit,
                phone: doc.data().phone,
                requests: doc.data().requests,
                likes: doc.data().likes,
                prompt: doc.data().prompt,
                date: shortdate
            })
        })
    } else { console.log('no stores yet') }
    setItems(data)


}
export const getUserShares = async (uid, setItems) => {

    if (uid) {

        const q = query(
            collection(db, COLLECTIONS.CV),
            where('uid', '==', uid),
            orderBy('created', 'desc'),
            limit(100)
        )

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            if (!querySnapshot.empty) {
                const data = []
                querySnapshot.forEach((doc) => {
                    const created = doc.data().created
                    const date = created.toDate()
                    const shortdate = date.toLocaleDateString("en-GB")
                    data.push({
                        id: doc.id,
                        title: doc.data().title,
                        hits: doc.data().hits,
                        files: doc.data().files,
                        uid: doc.data().uid,
                        menuid: doc.data().menuid,
                        phone: doc.data().phone ?? '',
                        description: doc.data().description,
                        caption: doc.data().caption,
                        dominantColor: doc.data().dominantColor,
                        lock: doc.data().lock ?? false,
                        hidden: doc.data().hidden ?? false,
                        date: shortdate,
                        credit: doc.data().credit ?? 0,
                        charge: doc.data().charge ?? 0,
                    })
                })
                // console.log(data)
                setItems(data)
            } else { console.log('nothing yet') }

        });
        return unsubscribe
    }

}

export const getMenus = async (pos, setItems) => {
    if (pos) {
        const center = [pos.lat, pos.lng];
        const data = []
        const bounds = geohashQueryBounds(center, radiusInM);
        const promises = [];
        for (const b of bounds) {
            const q = query(
                collection(db, COLLECTIONS.MENUS),
                where('active', '==', true),
                orderBy('geohash', 'asc'),
                startAt(b[0]),
                endAt(b[1]),
                limit(500)
            )
            promises.push(getDocs(q));
        }

        // Collect all the query results together into a single list
        Promise.all(promises).then((snapshots) => {

            for (const snap of snapshots) {
                for (const doc of snap.docs) {
                    const lat = doc.get('lat');
                    const lng = doc.get('lng');

                    // We have to filter out a few false positives due to GeoHash
                    // accuracy, but most will match
                    const distanceInKm = distanceBetween([lat, lng], center);
                    const distanceInM = distanceInKm * 1000;
                    if (distanceInM <= radiusInM) {

                        const created = doc.data().created
                        const date = created.toDate()
                        const shortdate = date.toLocaleDateString("en-GB")

                        const push = {
                            id: doc.id,
                            title: doc.data().title,
                            image: doc.data().image,
                            desc: doc.data().desc,
                            limit: doc.data().limit,
                            dist: distanceInKm,
                            within: doc.data().limit >= distanceInKm,
                            phone: doc.data().phone,
                            requests: doc.data().requests,
                            likes: doc.data().likes,
                            prompt: doc.data().prompt,
                            date: shortdate
                        }
                        data.push(push);
                    }
                }
            }

            return data.sort((a, b) => (a.dist > b.dist ? 1 : -1))

        }).then((docs) => {
            setItems(docs)
        });
    } else {

        // const q = query(
        //     collection(db, COLLECTIONS.MENUS),
        //     where('active', '==', true),
        //     orderBy("created", "desc"),
        //     limit(20)
        // )
        // const querySnapshot = await getDocs(q);
        // const data = []

        // querySnapshot.forEach((doc) => {
        //     data.push({
        //         id: doc.id,
        //         dist: null,
        //         title: doc.data().title,
        //         image: doc.data().image,
        //         desc: doc.data().desc,
        //         requests: doc.data().requests,
        //         phone: doc.data().phone
        //     })
        // })
        // setItems(data)
    }
}

const like = async (data, onLike) => {
    console.log('liking')
    const d = {
        ...data,
        status: 1,
        time: Timestamp.fromDate(new Date()),
    }
    const col1 = collection(db, `${COLLECTIONS.MENUS}/${data.id}/likes`)
    const col2 = collection(db, `${COLLECTIONS.USERS}/${data.uid}/likes`)

    const d1 = doc(col1, data.uid);
    const d2 = doc(col2, data.id);
    await setDoc(d1, d, { merge: true })
    await setDoc(d2, d, { merge: true })
    await menuUpdate(data.id, { likes: increment(1) })

    onLike(true)
}
const unlike = async (data, onLike) => {
    console.log('unliking')
    const d = {
        ...data,
        status: 0,
        time: Timestamp.fromDate(new Date()),
    }
    console.log(d)

    const d1 = doc(db, `${COLLECTIONS.MENUS}/${data.id}/likes`, data.uid);
    const d2 = doc(db, `${COLLECTIONS.USERS}/${data.uid}/likes`, data.id);
    await setDoc(d1, d, { merge: true })
    await setDoc(d2, d, { merge: true })
    data.likes > 0 && await menuUpdate(data.id, { likes: increment(-1) })
    onLike(false)
}

export const likeUnlike = async (data, onLike) => {
    const docSnap = await getDoc(doc(db, `${COLLECTIONS.MENUS}/${data.id}/likes`, data.uid));

    if (docSnap.exists()) {
        if (docSnap.data().status === 0) {
            await like(data, onLike)
        } else {
            await unlike(data, onLike)
        }
    } else {
        await like(data, onLike)
    }
}

const save = async (data, onSave) => {
    console.log('saving')
    const d = {
        ...data,
        status: 1,
        time: Timestamp.fromDate(new Date()),
    }
    console.log(d)
    const col1 = collection(db, `${COLLECTIONS.PRELOVED}/${data.id}/saves`)
    const col2 = collection(db, `${COLLECTIONS.USERS}/${data.uid}/saves`)

    const d1 = doc(col1, data.uid);
    const d2 = doc(col2, data.id);
    await setDoc(d1, d, { merge: true })
    await setDoc(d2, d, { merge: true })
    await prelovedUpdate(data.id, { saves: increment(1) })

    onSave()
}

const unsave = async (data, onSave) => {
    console.log('unsaving')
    const d = {
        ...data,
        status: 0,
        time: Timestamp.fromDate(new Date()),
    }
    console.log(d)

    const d1 = doc(db, `${COLLECTIONS.PRELOVED}/${data.id}/saves`, data.uid);
    const d2 = doc(db, `${COLLECTIONS.USERS}/${data.uid}/saves`, data.id);
    await setDoc(d1, d, { merge: true })
    await setDoc(d2, d, { merge: true })
    data.saves > 0 && await prelovedUpdate(data.id, { saves: increment(-1) })
    onSave()
}

export const saveUnsave = async (data, onSave) => {
    const docSnap = await getDoc(doc(db, `${COLLECTIONS.PRELOVED}/${data.id}/saves`, data.uid));

    if (docSnap.exists()) {
        if (docSnap.data().status === 0) {
            await save(data, onSave)
        } else {
            await unsave(data, onSave)
        }
    } else {
        await save(data, onSave)
    }
}

export const getLikes = async (id) => {
    const q = query(
        collection(db, `${COLLECTIONS.USERS}/${id}/likes`),
        where('status', '==', 1)
    )

    const querySnapshot = await getDocs(q);
    const likes = []
    if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
            likes.push({
                key: doc.id,
                id: doc.data().id,
                uid: doc.data().uid,
                status: doc.data().status,
            })
        })
    } else { console.log('no likes yet') }
    return likes
}
export const getSaves = async (id) => {
    const q = query(
        collection(db, `${COLLECTIONS.USERS}/${id}/saves`),
        where('status', '==', 1)
    )

    const querySnapshot = await getDocs(q);
    const saves = []
    if (!querySnapshot.empty) {
        const s = []
        querySnapshot.forEach((doc) => {
            s.push(doc.data().id)
        })

        if (s.length > 0) {
            const q2 = query(
                collection(db, COLLECTIONS.PRELOVED),
                where('code', 'in', s),
            )

            const qs = await getDocs(q2);
            if (!qs.empty) {
                qs.forEach((doc) => {

                    const caption = doc.data()["caption"]

                    const posted = doc.data()["posted"].toDate()
                    saves.push({
                        id: doc.id,
                        thumb: doc.data()["images"],
                        caption: caption,
                        status: doc.data()["status"],
                        saves: doc.data()["saves"],
                        code: doc.data()["code"],
                        posted: posted
                    })

                })
            } else { console.log('no saves yet') }
        }
    } else { console.log('no saves yet') }

    return saves
}

export const getFavs = async (pos, setFavs, uid) => {

    const objarr = await getLikes(uid)
    if (objarr.length > 0) {
        const favourites = objarr.map(item => item['id'])
        const center = [pos.lat, pos.lng];
        const data = []
        const bounds = geohashQueryBounds(center, radiusInM);
        const promises = [];
        for (const b of bounds) {
            const q = query(
                collection(db, COLLECTIONS.MENUS),
                where('active', '==', true),
                where('id', 'in', favourites),
                orderBy('geohash', 'asc'),
                startAt(b[0]),
                endAt(b[1]),
                limit(200)
            )
            promises.push(getDocs(q));
        }

        // Collect all the query results together into a single list
        Promise.all(promises).then((snapshots) => {

            for (const snap of snapshots) {
                for (const doc of snap.docs) {
                    const lat = doc.get('lat');
                    const lng = doc.get('lng');

                    // We have to filter out a few false positives due to GeoHash
                    // accuracy, but most will match
                    const distanceInKm = distanceBetween([lat, lng], center);
                    const distanceInM = distanceInKm * 1000;
                    if (distanceInM <= radiusInM) {

                        const created = doc.data().created
                        const date = created.toDate()
                        const shortdate = date.toLocaleDateString("en-GB")

                        const push = {
                            id: doc.id,
                            title: doc.data().title,
                            image: doc.data().image,
                            desc: doc.data().desc,
                            limit: doc.data().limit,
                            dist: distanceInKm,
                            within: doc.data().limit >= distanceInKm,
                            phone: doc.data().phone,
                            requests: doc.data().requests,
                            likes: doc.data().likes,
                            prompt: doc.data().prompt,
                            date: shortdate
                        }
                        data.push(push);
                    }
                }
            }

            return data.sort((a, b) => (a.dist > b.dist ? 1 : -1))

        }).then((docs) => {
            setFavs(docs)
        });
    } else { setFavs([]) }
}

export const getLoved = async (setLoved, lim) => {

    const q = query(
        collection(db, COLLECTIONS.PRELOVED),
        where('active', '==', true),
        orderBy('posted', 'desc'),
        limit(lim)
    )


    const querySnapshot = await getDocs(q);
    const loved = []
    if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
            const caption = doc.data()["caption"]
            if (!caption.toLocaleLowerCase().includes("we ") && !caption.toLocaleLowerCase().includes("our ") && !caption.toLocaleLowerCase().includes("sold")) {
                const posted = doc.data()["posted"].toDate()
                loved.push({
                    id: doc.id,
                    thumb: doc.data()["images"],
                    caption: doc.data()["caption"],
                    status: doc.data()["status"],
                    saves: doc.data()["saves"],
                    posted: posted,
                    active: doc.data().active,
                    version: doc.data().version,
                    phone: doc.data().phone,
                    uid: doc.data().uid,
                    code: doc.data()["code"]
                })
            }
        })
        setLoved(loved)
    } else { console.log('no loved yet') }
}

export const getLovedLink = async (id) => {

    const ref = doc(db, COLLECTIONS.PRELOVED, id)
    const d = await getDoc(ref)
    if (d.exists()) {


        const data = {
            id: d.id,
            thumb: d.data()["images"],
            caption: d.data()["caption"],
            status: d.data()["status"],
            saves: d.data()["saves"],
            posted: d.data()["posted"].toDate(),
            active: d.data().active,
            version: d.data().version,
            phone: d.data().phone,
            uid: d.data().uid,
            code: d.data()["code"]
        }

        return data
    } else { return null }
}
export const getMyLoved = async (uid, setMyLoved) => {

    const q = query(
        collection(db, COLLECTIONS.PRELOVED),
        where('uid', '==', uid),
        orderBy('posted', 'desc')
    )

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const data = []
        querySnapshot.forEach((doc) => {
            const posted = doc.data()["posted"].toDate()
            data.push({
                id: doc.id,
                thumb: doc.data()["images"],
                caption: doc.data()["caption"],
                status: doc.data()["status"],
                saves: doc.data()["saves"],
                posted: posted,
                active: doc.data().active,
                version: doc.data().version,
                phone: doc.data().phone,
                uid: doc.data().uid,
                code: doc.data()["code"]
            })
        })
        setMyLoved(data)
    });
    return unsubscribe
}

const uploadProductImage = async (file, setPercent, uid, pid) => {
    const extension = file.name.split('.').pop()
    const path = `/applymycv/products/${uid}/${pid}.${extension}`
    console.log(path)
    const storageRef = ref(storage, path)
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
        "state_changed",
        (snapshot) => {
            const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            )
            // update progress
            setPercent(percent)
        },
        (err) => {
            console.log(err)
        },
        async () => {
            // download url
            await getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                await setProductUpdate(pid, { image: url })
            });
        }
    );
}
const uploadStoreImage = async (file, setPercent, uid, pid, handleFetch) => {
    const extension = file.name.split('.').pop()
    const path = `/app/stores/${uid}/${pid}.${extension}`
    console.log(path)
    const storageRef = ref(storage, path)
    const uploadTask = uploadBytesResumable(storageRef, file);
    await uploadTask.on(
        "state_changed",
        (snapshot) => {
            const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            )
            // update progress
            setPercent(percent)
        },
        (err) => {
            console.log(err)
        },
        async () => {
            // download url
            await getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                await menuUpdate(pid, { image: url })
                handleFetch?.()
            });
        }
    );
}
export const getGeoHash = async (data) => {
    return geohashForLocation([data.lat, data.lng])
}

export const updateProduct = async (file, setPercent, data, closeModal, handleFetch) => {

    await setProductUpdate(data.id, data)
    if (file) {
        await uploadProductImage(file, setPercent, data.uid, data.id)
    }
    closeModal()
    handleFetch()

}

export const updateMenu = async (file, setPercent, data, closeModal, handleFetch) => {

    if (file) {
        await uploadStoreImage(file, setPercent, data.id, data.id, handleFetch)
    }
    await menuUpdate(data.id, data)
    closeModal()
    handleFetch()

}

export const captureRequest = async (data) => {
    const id = data.vendor
    const col = collection(db, COLLECTIONS.REQUESTS)
    const request = await addDoc(col, data)
    if (id !== '') {
        await menuUpdate(id, { requests: increment(1) })
    }
    return request.id
}

export const getRequest = async (id, setItem, process) => {

    const ref = doc(db, COLLECTIONS.REQUESTS, id)
    const unsub = onSnapshot(ref, async (d) => {
        if (d.exists()) {
            const data = {
                id: d.id,
                cart: d.data().cart,
                logged: d.data().logged,
                location: d.data().location,
                paid: d.data().paid,
                vendor: await getMenu(d.data().vendor),
                debitedAt: d.data().debitedAt,
            }
            setItem(process(data))
        }
    })
    return unsub
}


const menuUpdate = async (id, data) => {
    const col = doc(db, COLLECTIONS.MENUS, id)
    await setDoc(col, data, { merge: true })
}
export const prelovedUpdate = async (id, data) => {
    const col = doc(db, COLLECTIONS.PRELOVED, id)
    await setDoc(col, data, { merge: true })
}



const setProductUpdate = async (id, data) => {
    const col = doc(db, COLLECTIONS.PRODUCTS, id)
    await setDoc(col, data, { merge: true })
}

export const addProduct = async (file, setPercent, data, handleFetch, handleDish, closeModal) => {
    const hash = geohashForLocation([data.lat, data.lng])
    console.log('hash', data)

    const ref = doc(db, COLLECTIONS.CONSTS, 'app')
    const d = await getDoc(ref)
    const charge = d.data().charge
    const days = d.data().days
    console.log('const', d.data())

    const product = {
        ...data,
        active: !charge,
        geohash: hash,
        created: Timestamp.fromDate(new Date()),
    }

    const col = collection(db, COLLECTIONS.PRODUCTS)
    const p = await addDoc(col, product)

    if (file) {
        await uploadProductImage(file, setPercent, data.uid, p.id)
    }

    if (charge) {
        handleDish({ id: p.id, ...product })
    } else {
        updateDays(p.id, days)
        closeModal()
    }

    handleFetch()

}

export const addMenu = async (file, setPercent, data, navigateToAccount) => {
    const hash = geohashForLocation([data.lat, data.lng])
    console.log('hash', data)
    const d = {
        ...data,
        geohash: hash,
        created: Timestamp.fromDate(new Date()),
    }
    const col = collection(db, COLLECTIONS.MENUS)
    const m = await addDoc(col, d)
    const update = {
        menus: arrayUnion(m.id),
        phone: data.phone,
        isVendor: true
    }
    await updateUser(data.uid, update)
    if (file) {
        await uploadStoreImage(file, setPercent, data.uid, data.uid, navigateToAccount)
    } else {
        navigateToAccount()
    }
}
export const getShared = async (id, setItem, setShares, completed) => {

    const ref = doc(db, COLLECTIONS.SHARED, id)
    // console.log(id)

    const d = await getDoc(ref)

    const unsub = onSnapshot(
        ref,
        { includeMetadataChanges: true },
        async (d) => {

            const doc_data = {
                id: d.id,
                caption: d.data().description,
                hits: d.data().hits,
                thumb: d.data().thumb,
                images: d.data().images,
                title: d.data().title,
                uid: d.data().uid,
                phone: d.data().phone,
                menuid: d.data().menuid,
                dominantColor: d.data().dominantColor,
                lock: d.data().lock ?? false,
                hidden: d.data().hidden ?? false,
                charge: d.data().charge,
                credit: d.data().credit ?? 0,

            }
            setItem(doc_data)

            // await getUserShares(doc_data.uid, setShares)
        })


    completed(true)

    return unsub


}

export const incrementView = async (data) => {

    const ref = doc(db, COLLECTIONS.SHARED, data.id)
    const moment = getMoment()
    console.log('hit')
    const update = {
        hits: increment(1),
        items: arrayUnion({
            src: COLLECTIONS.SHARED,
            id: data.id,
            title: data.title,
            time: moment.time,
        })
    }
    await setDoc(ref, update, { merge: true })
}

export const updateShared = async (id, data) => {
    await setDoc(doc(db, COLLECTIONS.SHARED, id), data, { merge: true })
}
export const updateSharedImage = async (id, data, old) => {
    await updateDoc(doc(db, COLLECTIONS.SHARED, id), { images: arrayRemove(old) })
    await updateDoc(doc(db, COLLECTIONS.SHARED, id), { images: arrayUnion({ pos: data.pos, link: data.link, caption: data.caption, url: data.url, time: data.time, public: data.public }) })
}

const uploadResume = async (files, uid, pid, collection, j, update, data) => {
    await files.forEach(async (file, idx) => {
        const i = idx + j
        const extension = file.name.split('.').pop()
        const moment = getMoment()
        const path = `/app/${collection.toLowerCase()}/${uid}/${pid}_${i}.${extension}`
        const url = await uploadTaskPromise(path, file)
        update({ files: arrayUnion({ pos: i, name: file.name, url: url, time: moment.time, public: data.public }) })
    })
}
export const captureResume = async (file, data, closeListing, img_data) => {

    const col = collection(db, COLLECTIONS.CV)

    const post = await addDoc(col, {
        ...data,
        created: Timestamp.fromDate(new Date())
    })

    var i = 0
    const update = async (data) => {
        i = i + 1
        await setDoc(doc(db, COLLECTIONS.CV, post.id), data, { merge: true })
        console.log(i)
        if (i === file.length) {
            closeListing()
        }
    }
    await uploadResume(file, data.uid, post.id, COLLECTIONS.CV, 0, update, img_data)

    const link = `https://applymycv.com/cv/${post.id}`
    return link
}

export const addToCollection = async (file, data, closeListing) => {

    var i = 0
    const update = async (img) => {
        i = i + 1
        await setDoc(doc(db, COLLECTIONS.SHARED, data.id), img, { merge: true })
        console.log(i)
        if (i === file.length) {
            closeListing()
        }
    }
    await uploadShareImages(file, data.uid, data.id, COLLECTIONS.SHARED, data.size, update, data)
}

export const addSharable = async (file, data, closeListing, img_data) => {

    const col = collection(db, COLLECTIONS.SHARED)

    const post = await addDoc(col, {
        ...data,
        created: Timestamp.fromDate(new Date())
    })

    var i = 0
    const update = async (data) => {
        i = i + 1
        await setDoc(doc(db, COLLECTIONS.SHARED, post.id), data, { merge: true })
        console.log(i)
        if (i === file.length) {
            closeListing()
        }
    }
    await uploadShareImages(file, data.uid, post.id, COLLECTIONS.SHARED, 0, update, img_data)

    const link = `https://applymycv.com//${post.id}`
    return link
}

const uploadShareImages = async (files, uid, pid, collection, j, update, data) => {
    await files.forEach(async (file, idx) => {
        const i = idx + j
        const extension = file.name.split('.').pop()
        const moment = getMoment()
        if (i === 0) {
            const path = `/thumbs/${uid}/${pid}${i}.${extension}`
            const url = await uploadTaskPromise(path, file)
            const thumb = url.replace(`${pid}${i}`, `${pid}${i}_600x600`)
            await setDoc(doc(db, COLLECTIONS.SHARED, pid), { thumb: thumb }, { merge: true })
        }
        const path = `/app/${collection.toLowerCase()}/${uid}/${pid}${i}.${extension}`
        console.log(path)
        const url = await uploadTaskPromise(path, file)
        update({ images: arrayUnion({ pos: i, caption: data.caption, link: data.link, url: url, time: moment.time, public: data.public }) })
    })
}

export const addListing = async (file, setPercent, data, closeListing) => {
    const hash = geohashForLocation([data.lat, data.lng])
    const d = {
        ...data,
        geohash: hash,
        posted: Timestamp.fromDate(new Date()),
        version: 2,
    }
    const col = collection(db, COLLECTIONS.PRELOVED)

    const post = await addDoc(col, d)
    await updateUser(data.uid, {
        phone: data.phone
    })
    const update = async (data) => {
        await prelovedUpdate(post.id, { code: post.id, ...data })
    }

    await uploadImages(file, closeListing, data.uid, post.id, COLLECTIONS.PRELOVED, update)




    console.log('done')

}
const uploadTaskPromise = async (path, file) => {

    console.log(path)
    return new Promise((resolve, reject) => {
        const storageRef = ref(storage, path)
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                )
                // update progress
                // setPercent(percent)
            },
            (err) => {
                console.log(err)
                reject()
            },
            async () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    resolve(url)
                });
            }
        )
    })
}

const uploadImages = async (files, closeListing, uid, pid, collection, update) => {
    await files.forEach(async (file, i) => {
        const extension = file.name.split('.').pop()
        const path = `/app/${collection.toLowerCase()}/${uid}/${pid}${i}.${extension}`
        const url = await uploadTaskPromise(path, file)
        update({ images: arrayUnion(url) })
        closeListing(pid)
    })
}

export const updateDays = async (id, days) => {

    const now = new Date()
    const then = addDays(now, days)

    const data = {
        days: days,
        from: now,
        until: then
    }

    const col = doc(db, COLLECTIONS.PRODUCTS, id)
    await setDoc(col, data, { merge: true })
}

export const getProducts = async (id, setItems) => {
    logEvent(analytics, "user products request", { account: id })
    const q = query(
        collection(db, COLLECTIONS.PRODUCTS),
        where('menuid', '==', id),
        orderBy("created", "desc"),
        limit(2000)
    )


    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const data = []
        querySnapshot.forEach((doc) => {

            const until = doc.data().until
            const date = until ? until.toDate() : null
            const shortdate = date ? date.toLocaleDateString("en-GB") : 'ACTIVE'
            data.push({
                id: doc.id,
                dist: 0,
                name: doc.data().name,
                enabled: doc.data().enabled,
                active: doc.data().active,
                from: doc.data().from,
                until: shortdate,
                discounted: doc.data().discounted,
                discount: doc.data().discount < 0 ? '' : doc.data().discount,
                image: doc.data().image,
                uname: doc.data().uname,
                price: doc.data().price,
                symbol: doc.data().symbol,
                currency: doc.data().currency,
                phone: doc.data().phone,
                desc: doc.data().desc
            })
        })
        setItems(data)
    });
    return unsubscribe
}

export const grabNearby = (pos, setItems) => {
    logEvent(analytics, "products request", { pos: pos })

    if (pos.lat && pos.lng) {

        const center = [pos.lat, pos.lng];

        const data = []
        const bounds = geohashQueryBounds(center, radiusInM);
        const promises = [];
        for (const b of bounds) {
            const q = query(
                collection(db, COLLECTIONS.PRODUCTS),
                where('active', '==', true),
                orderBy('geohash', 'asc'),
                startAt(b[0]),
                endAt(b[1]),
                limit(2000)
            )
            promises.push(getDocs(q));
        }

        // Collect all the query results together into a single list
        Promise.all(promises).then((snapshots) => {

            for (const snap of snapshots) {
                for (const doc of snap.docs) {
                    const lat = doc.get('lat');
                    const lng = doc.get('lng');

                    // We have to filter out a few false positives due to GeoHash
                    // accuracy, but most will match
                    const distanceInKm = distanceBetween([lat, lng], center);
                    const distanceInM = distanceInKm * 1000;
                    if (distanceInM <= radiusInM) {

                        const until = doc.data().until
                        const date = until ? until.toDate() : ''
                        const shortdate = until ? date.toLocaleDateString("en-GB") : ''
                        const push = {
                            dist: distanceInKm,
                            id: doc.id,
                            name: doc.data().name,
                            enabled: doc.data().enabled,
                            discounted: doc.data().discounted,
                            active: doc.data().active,
                            from: doc.data().from,
                            until: shortdate,
                            discount: doc.data().discount < 0 ? '' : doc.data().discount,
                            image: doc.data().image,
                            price: doc.data().price,
                            symbol: doc.data().symbol,
                            uname: doc.data().uname,
                            phone: doc.data().phone,
                            currency: doc.data().currency,
                            desc: doc.data().desc
                        }
                        data.push(push);
                    }
                }
            }

            return data.sort((a, b) => (a.dist > b.dist ? 1 : -1))

        }).then((docs) => {
            setItems(docs)
        });

    } else {
        const q = query(
            collection(db, COLLECTIONS.PRODUCTS),
            where('active', '==', true),
            orderBy("created", "desc"),
            limit(2000)
        )


        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const data = []
            querySnapshot.forEach((doc) => {

                const until = doc.data().until
                const date = until ? until.toDate() : null
                const shortdate = date ? date.toLocaleDateString("en-GB") : 'ACTIVE'
                data.push({
                    id: doc.id,
                    dist: 0,
                    name: doc.data().name,
                    enabled: doc.data().enabled,
                    active: doc.data().active,
                    from: doc.data().from,
                    until: shortdate,
                    discounted: doc.data().discounted,
                    discount: doc.data().discount < 0 ? '' : doc.data().discount,
                    image: doc.data().image,
                    price: doc.data().price,
                    symbol: doc.data().symbol,
                    currency: doc.data().currency,
                    phone: doc.data().phone,
                    desc: doc.data().desc
                })
            })
            setItems(data)
        });
        return unsubscribe
    }
}