import React, { Fragment, useEffect, useState } from 'react'
import Menu from '../pages/Menu'
import { likeUnlike } from '../firebaseConfig'
import { handleSignIn } from '../helpers'

const Listing = ({ item, handleFaves, user, pos, handleShare, navigateToMenu }) => {
    const image = item.image
    const dist = item.dist
    const desc = item.desc
    const requests = item.requests
    const within = item.within

    const distval = dist < 1 ? `${(dist * 1000).toFixed(2)} m away` : `${dist.toFixed(2)} km away`

    const [toggleSearch, setToggle] = useState(false)
    const [search, setSearch] = useState('')
    const [disabled, setDisabled] = useState(true)
    const onLike = (isLike) => {
        // setLiked(isLike)
        handleFaves()
    }

    useEffect(() => {
        // console.log(user)
        setDisabled(!user || !user.active)
    }, [user])

    const handleClick = () => {
        if (toggleSearch) {
            setSearch('')
        }
        setToggle(!toggleSearch)
    }
    const handleLike = (id, likes) => {
        if (user) {
            const data = {
                id: id,
                likes: likes,
                uid: user.uid
            }
            likeUnlike(data, onLike)
        } else {
            handleSignIn()
        }
    }

    const Like = () => {
        return (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
        </svg>
        )
    }

    const Liked = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                <path d="M9.653 16.915l-.005-.003-.019-.01a20.759 20.759 0 01-1.162-.682 22.045 22.045 0 01-2.582-1.9C4.045 12.733 2 10.352 2 7.5a4.5 4.5 0 018-2.828A4.5 4.5 0 0118 7.5c0 2.852-2.044 5.233-3.885 6.82a22.049 22.049 0 01-3.744 2.582l-.019.01-.005.003h-.002a.739.739 0 01-.69.001l-.002-.001z" />
            </svg>

        )
    }

    return (

        <button className="w-[90vw] md:w-[30vw] border flex flex-col rounded-2xl shadow-sm my-1"
            // disabled={disabled}
            onClick={() => navigateToMenu(item)}>

            <div className={`flex items-center w-full rounded-xl py-4 space-x-2 px-4 `}>
                {toggleSearch ?
                    <input
                        className={`${item?.editor ? 'border-green-400' : 'border-white'} border-0 focus:border-0 w-full h-10 md:h-12 font-light py-2 px-4 text-sm text-start text-black line-clamp-1 overflow-hidden bg-white bg-opacity-80 rounded-full`}
                        placeholder={item?.title}
                        autoFocus='false'
                        type='text'
                        onChange={(e) => { setSearch(e.target.value) }} />
                    :
                    <div className={`flex flex-col w-full items-stretch rounded-t-2xl  flex-1 space-x-2`}>
                        <img alt='' src={item?.image}
                            className={`${item?.editor ? 'cursor-pointer' : ''} border-white border-0 ml-1 w-full h-36 md:h-48 object-cover object-center rounded-2xl md:rounded-3xl`} loading='lazy' />

                        <div className='flex justify-between pt-2 px-4'>

                            <div className='flex-1 flex flex-row items-center justify-between '>
                                <span className={`font-light px-2 text-xs text-start text-black line-clamp-1`}>{`${item?.title}`}</span>
                                {pos && item?.dist &&
                                    <span className={`${item?.within ? 'bg-green-500 font-light' : 'bg-red-500 font-light'} text-white px-2 py-[1px] rounded line-clamp-1 text-[8px]`}>{`${item?.within ? 'Nearby:' : 'Distant:'} ${item?.dist*5 < 60 ? `${(1+item?.dist * 5)?.toFixed(0) ?? '--'} minute${(1+item?.dist * 5)?.toFixed(0)=== '1'?'':'s'} away` : `${((item?.dist*5)/60)?.toFixed(0) ?? '--'} ${((item?.dist*5)/60)?.toFixed(0)==='1'?'hour':'hrs'} away`}`}</span>
                                }
                            </div>


                            {/* <div className='flex justify-center items-center'>
                                <span className={`text-black px-2 py-[1px] rounded line-clamp-1 text-[8px]`}>{requests}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                    <path fillRule="evenodd" d="M4.25 2A2.25 2.25 0 0 0 2 4.25v11.5A2.25 2.25 0 0 0 4.25 18h11.5A2.25 2.25 0 0 0 18 15.75V4.25A2.25 2.25 0 0 0 15.75 2H4.25ZM15 5.75a.75.75 0 0 0-1.5 0v8.5a.75.75 0 0 0 1.5 0v-8.5Zm-8.5 6a.75.75 0 0 0-1.5 0v2.5a.75.75 0 0 0 1.5 0v-2.5ZM8.584 9a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0v-4.5a.75.75 0 0 1 .75-.75Zm3.58-1.25a.75.75 0 0 0-1.5 0v6.5a.75.75 0 0 0 1.5 0v-6.5Z" clipRule="evenodd" />
                                </svg>
                            </div> */}
                        </div>
                    </div>
                }

                {/* <button
                    onClick={handleClick}>
                    {toggleSearch ?
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                        </svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                            <path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clipRule="evenodd" />
                        </svg>

                    }

                </button> */}

                {/* <button
                    onClick={() => handleLike(item?.id, item?.likes)}>
                    {liked ?
                        <Liked />
                        :
                        <Like />

                    }

                </button> */}

                {/* {item?.id && <button
                    onClick={() => handleShare(item)}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                        <path d="M13 4.5a2.5 2.5 0 11.702 1.737L6.97 9.604a2.518 2.518 0 010 .792l6.733 3.367a2.5 2.5 0 11-.671 1.341l-6.733-3.367a2.5 2.5 0 110-3.475l6.733-3.366A2.52 2.52 0 0113 4.5z" />
                    </svg>
                </button>} */}
            </div>
            {/* <div className='w-full flex-1 overflow-auto'>

                <Menu editor={false} user={user} menuid={item.id} search={search}  />
            </div> */}

        </button>
    )
}

export default Listing