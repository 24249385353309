import React from 'react'

const FavouriteListing = ({ item, navigateToMenu }) => {

    const within = item.within
    const likes = item.likes

    return (
        <button onClick={() => navigateToMenu(item)}
            className={`flex flex-col justify-start max-w-sm w-1/3 p-2 space-y-2`}>
            <div className={`flex space-y-2 h-full flex-col items-center my-1 justify-start w-full py-2 ${within ? ' border-green-500' : 'border-red-500'} rounded-lg bg-slate-50 px-2 border-b hover:shadow-lg`}>
                <div className={`${within ? ' bg-green-500' : 'bg-red-500'} text-white flex flex-col items-center px-2 rounded w-full`}>
                    {/* <span className='text-[7px] '>{`${likes}`}</span> */}
                    {item?.dist && <span className={`${item?.within ? 'bg-green-500 font-light' : 'bg-red-500 font-light'} text-white rounded  text-[6px]`}>{`${item?.within ? 'Nearby' : 'Distant'}`}</span>}
                    {item?.dist && <span className={`${item?.within ? 'bg-green-500 font-light' : 'bg-red-500 font-light'} text-white rounded  text-[6px]`}>{`${item?.dist < 1 ? `${(item?.dist * 1000)?.toFixed(2) ?? '--'} m` : `${item?.dist?.toFixed(2) ?? '--'} km`}`}</span>}
                    {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-2 h-2">
                        <path d="M9.653 16.915l-.005-.003-.019-.01a20.759 20.759 0 01-1.162-.682 22.045 22.045 0 01-2.582-1.9C4.045 12.733 2 10.352 2 7.5a4.5 4.5 0 018-2.828A4.5 4.5 0 0118 7.5c0 2.852-2.044 5.233-3.885 6.82a22.049 22.049 0 01-3.744 2.582l-.019.01-.005.003h-.002a.739.739 0 01-.69.001l-.002-.001z" />
                    </svg> */}
                </div>
                <img alt=''
                    src={item?.image}
                    className={`${item?.editor ? 'cursor-pointer' : ''} border w-12 h-12 md:w-14 md:h-14 object-cover object-center rounded-full bg-slate-500`}
                    loading='lazy' />

                <span className='text-[10px] font-light line-clamp-3'>{item?.title}</span>
            </div>
        </button>
    )
}

export default FavouriteListing