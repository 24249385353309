import React, { Fragment, useEffect, useState } from 'react'
import MenuList from '../components/MenuList'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { COLLECTIONS, addProduct, captureRequest, db, getGeoHash, getMenu, getProducts, grabNearby, signInWithGoogle, updateDays, updateMenu, updateProduct } from '../firebaseConfig';
import { Button, Fab, Link } from '@mui/material';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { Dialog, Switch, Transition } from '@headlessui/react';
import { distances, joinListWithCount, periods, toTitleCase, validatePhoneNumber } from '../helpers';
import Logo from '../assets/logo.png'
import { useDocument } from 'react-firebase-hooks/firestore';
import ReactLoading from 'react-loading';
import { doc } from 'firebase/firestore';
import { Refresh } from '@mui/icons-material';

const Menu = ({ prod, handleTitle, editor, user, menuid, search, handleLocation, isparam, closePopUp }) => {
  const params = useParams();

  const [period, setPeriod] = useState(periods[1])
  const [limit, setLimit] = useState(distances[0].max)
  const [items, setItems] = useState(null)
  const [dish, setDish] = useState(null)
  const [cart, setCart] = useState([])
  const [isOpen, setIsOpen] = useState(0)
  const [store, setStore] = useState('')
  const [name, setName] = useState('')
  const [desc, setDesc] = useState('')
  const [price, setPrice] = useState('')
  const [symbol, setSymbol] = useState('')
  const [phone, setPhone] = useState('')
  const [msisdn, setmsisdn] = useState('')
  const [charge, setCharge] = useState(0)
  const [discount, setDiscount] = useState('')
  const [file, setFile] = useState("");
  const [percent, setPercent] = useState(0)
  const [state, setState] = useState(null)
  const [dist, setDist] = useState(null)
  const [enabled, setEnabled] = useState(true)
  const [locate, setLocate] = useState(false)
  const [active, setActive] = useState(false)
  const [requesting, setRequesting] = useState(false)
  const [discounted, setDiscounted] = useState(false)
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [locerr, setLocErr] = useState('');
  const [locsugg, setLocSugg] = useState('');
  const [prompting, setPrompting] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const sleep = async (ms) => { return new Promise(resolve => setTimeout(resolve, ms)); }

  const [value, loading, error] = useDocument(doc(db, COLLECTIONS.CONSTS, "app"), { snapshotListenOptions: { includeMetadataChanges: true } });

  const [lat, setLat] = useState(null)
  const [lng, setLng] = useState(null)


  const handleMSISDNChange = (event) => {
    const phoneNumber = event.target.value
    const result = validatePhoneNumber(phoneNumber);

    if (result.result !== "invalid") {
      setmsisdn(result.result);
    } else {
      setmsisdn('')
    }
  }

  const SendPayload = async (payload, { setPrompting, setWaiting }) => {

    console.log(payload)

    if (payload.amount === '' || payload.msisdn === '') {
      alert("Missing info")
    } else {
      setPrompting(true);
      setWaiting(true);
      const url = `https://us-central1-firenode-86abd.cloudfunctions.net/app/gateway`;
      const body = `amount=${payload.amount}&msisdn=${payload.msisdn}&account_id=self/${payload.account_id}`;
      fetch(url, {
        body: body,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        method: "POST",
      })
        .then((response) => response.json())
        .then(async (data) => {
          console.log(data)

          if (data.success) {


            console.log("item", dish)
            await updateDays(dish.id, period.days)
          } else {

          };
          await sleep(12000)
          setWaiting(false)
          setPrompting(false)

          setIsOpen(0)
        })
        .catch((error) => {
          console.error('Error:', error)
          setPrompting(false)
          setWaiting(false)
          alert(error)
        });
    }
  }

  const handlePeriod = (p, d) => {
    setPeriod(p)
    const rate = (value?.data()?.["fee"] != null ? (value?.data()?.["fee"]) : 0)
    const price = d.price
    const cost = Math.ceil(parseInt(price) * rate) * p.days
    setCharge(Math.max(0, p.min))

    console.log(charge)
  }

  const handleDish = (d) => {
    setDish(d)
    handlePeriod(period, d)
    setmsisdn(d.phone)
    setIsOpen(2)
  }

  const here = async (grab) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocErr('')
          setLocSugg('')
          setLatitude(position.coords.latitude)
          setLongitude(position.coords.longitude)
          const pos = {
            lat: parseFloat(position.coords.latitude),
            lng: parseFloat(position.coords.longitude),
          }

          setLat(pos.lat)
          setLng(pos.lng)
          // grab && grabNearby(pos, setItems)
        },
        () => {
          console.log("Browser doesn't support Geolocation")
          setLocErr('Sorry, Geolocation is unsupported here or permissions were not given.')
          setLocSugg('This is needed to suggest nearby vendors')
          // handleLocationError(true, infoWindow, map.getCenter());
        }
      );
    } else {
      console.log("Browser doesn't support Geolocation")
      setLocErr('Sorry, Geolocation is unsupported here.')
      setLocSugg('Try a different browser.')
      // Browser doesn't support Geolocation
      // handleLocationError(false, infoWindow, map.getCenter());
    }
  }

  const closeModal = () => {
    setIsOpen(0)
    setCart([])
    setPercent(0)
    setLimit(distances[0].max)
    setFile("")
    setName('')
    setPrice('')
    setDiscount('')
    setDesc('')
    setEnabled(true)
    setActive(false)
    setmsisdn('')
    setDiscounted(false)
    setDish(null)
    setDist(null)
    setPhone('')
    setSymbol('')
  }

  const handleState = (res) => {
    setState(res)
    setLimit(res?.limit)
  }

  const handleFetch = () => {
    setItems(null)
    here(true)

    if (params.id) {
      if (params.id !== 'account') {
        console.log('params')
        getMenu(params.id).then((res) => { handleState(res) })
        getProducts(menuid, setItems)
      } else {
        if (user) {
          console.log('v', true)
          if(true){
          getMenu(user.uid).then((res) => { 
            if(res){
              handleState(res) 
            }else{
            }
          });
          getProducts(menuid, setItems).then((un) => { })
        }else{
          handleLocation(true)
          }
        } else { navigate(`/`) }
      }
    } else if (menuid) {
        getMenu(menuid).then((res) => { handleState(res) })
        getProducts(menuid, setItems)
    } else if (editor) {
      if (user) {
        if(true){
        getMenu(user.uid).then((res) => { handleState(res) })
        getProducts(menuid, setItems).then((un) => { })
        }else{
          handleLocation(true)
        }
      } else { navigate(`/`) }
    } else {

    }


    // getMenu(id).then((res) => {
    //   setItems(res)
    //   // setEditor(res.editor)
    //   // handleTitle(res.title)
    // })
  }

  const navigate = useNavigate();

  const navigateHome = () => {
    // 👇️ navigate
    navigate(`/account`, { uid: user.uid });
  }
  const fields = [
    {
      name: 'store',
      handler: setStore
    },
    {
      name: 'name',
      handler: setName
    },
    {
      name: 'phone',
      handler: setPhone,
      validator: validatePhoneNumber
    },
    {
      name: 'desc',
      handler: setDesc
    },
    {
      name: 'price',
      handler: setPrice
    },
    {
      name: 'file',
      handler: setFile
    },
    {
      name: 'limit',
      handler: setLimit
    }
  ]
  const handleChange = (event, field) => {
    const value = field === 'file' ? event.target.files[0] : event.target.value;

    const selectedField = fields.find(f => f.name === field);
    if (selectedField) {
      const { handler, validator } = selectedField;
      handler(value);

      if (field === 'phone' && validator) {
        const result = validator(value);
        setPhone(result.result !== 'invalid' ? result.result : '');
      }
    }
  }
  const handleUpload = async () => {
    if (lat && lng) {
      const data = {
        phone: phone,
        uid: user.uid,
        name: name,
        uname: user.displayName,
        price: parseInt(price),
        symbol: '/=',
        currency: 'KES',
        desc: desc,
        enabled: enabled,
        discounted: false,
        discount: -1,
        menuid: menuid,
        image: value?.data()?.["default"]??''

      }
        const geohash = await getGeoHash({ lat: lat, lng: lng })
        await addProduct(file, setPercent, { lat: lat, lng: lng, geohash: geohash, ...data }, handleFetch, handleDish, closeModal)
    } else {
      console.log('no pos')
    }
  }

  useEffect(() => {
    handleFetch()
  }, [params]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  }
  const handleDescChange = (event) => {
    setDesc(event.target.value);
  }
  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  }
  const handleDiscountChange = (event) => {
    setDiscount(event.target.value);
    if (event.target.value === '' || parseInt(event.target.value) < 0) { setDiscounted(false) }
  }
  const handlePhoneChange = (event) => {
    const phoneNumber = event.target.value
    const result = validatePhoneNumber(phoneNumber);
    console.log(result, phoneNumber)
    if (result.result !== "invalid") {
      setPhone(result.result);
    } else {
      setPhone('')
    }
  }
  const handleFileChange = (event) => {
    setPercent(0)
    setFile(event.target.files[0]);
  }
  const handleUpdate = async (dish) => {
    const disc = typeof parseInt(discount) === 'number' ? parseInt(discount) : -1
    const data = {
      id: dish?.id,
      phone: phone,
      name: name,
      price: parseInt(price),
      desc: desc,
      enabled: enabled,
      discounted: discounted,
      discount: disc,
      uname: user.displayName,
      uid: user.uid
    }

    if (lat && lng) {
      const geohash = await getGeoHash({ lat: lat, lng: lng })
      updateProduct(file, setPercent, { lat: lat, lng: lng, geohash: geohash, ...data }, closeModal, handleFetch)
    } else {
      updateProduct(file, setPercent, data, closeModal, handleFetch)
    }
  }
  const handleMenuUpdate = async () => {
    const data = {
      id: state.id,
      phone: phone,
      desc: desc,
      title: store,
      active: enabled,
      limit: limit,
    }

    if (lat && lng && locate) {
      const geohash = await getGeoHash({ lat: lat, lng: lng })
      updateMenu(file, setPercent, { lat: lat, lng: lng, geohash: geohash, ...data }, closeModal, handleFetch)
    } else {
      updateMenu(file, setPercent, data, closeModal, handleFetch)
    }
  }

  const openModal = () => {
    setIsOpen(1)
  }


  const handleCart = async (value, action) => {
    await sleep(115)

    const dist = value.dist
    const distval = dist < 1 ? (dist * 100) < 1 ? `${(dist * 100000).toFixed(2)} cm` : `${(dist * 1000).toFixed(2)} m` : `${dist.toFixed(2)} km`

    setDish(value)
    setDist(distval)
    setName(value.name)
    setDesc(value.desc)
    setPrice(value.price)
    setPhone(value.phone)
    setSymbol(value.symbol)
    setDiscounted(value.discounted)
    setDiscount(value.discount)
    setmsisdn(value.phone)

    if (value.active) {
      if (editor) {
        setEnabled(value.enabled)
        openModal()
      } else {
        if (action === 'ADD') {
          // console.log(value.id, value.name)
          setCart([...cart, value])
          // setIsOpen(-1)
        } else {
          const index = cart.indexOf(value)
          if (index >= 0) {
            cart.splice(index, 1)
            // console.log(index)
            setCart([...cart])
          }
        }
      }
    } else {
      handleDish(value)
    }
  }

  const addCart = (value) => {
    setCart([...cart, value])
  }

  const removeCart = (value) => {
    const index = cart.indexOf(value)
    if (index >= 0) {
      cart.splice(index, 1)
      console.log(index)
      setCart([...cart])
    }
  }

  const message = (rid) => {
    const texts = []
    var total = 0
    cart.forEach((item, idx) => {
      var charge = item.discounted ? item.discount : item.price
      // texts.push(`${item.name}${item.discounted?` ~@${item.price}/=~`:''} @ _${charge}/=_`)
      texts.push(`${item.name}`)
      total += charge
    })
    const text = joinListWithCount(texts)
    const date = new Date()
    const time = date.toLocaleTimeString([], { hour12: true, hour: "2-digit", minute: "2-digit" }).toUpperCase()
    const shortdate = date.toLocaleDateString("en-GB")
    const loc = `🧭 _Current location:_\nhttps://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`

    return encodeURIComponent(`*ApplymyCV* ${'```'}${shortdate}-${time}${'```'}\nhttps://applymycv.com/request/${rid}\n\n${text}\n*Est. Total: ${total}/=*\n\n\n\n${loc}`)
  }

  const toggleRequest = async (b) => {
    setRequesting(b)

  }

  const handleRequest = async () => {
    await toggleRequest(true)

    const data = {
      cart: cart.map(item => {
        return {
          id: item.id,
          name: item.name,
          phone: item.phone,
          price: item.price,
          discount: item.discount,
          discounted: item.discounted
        };
      }),
      logged: new Date(),
      location: { latitude: latitude, longitude: longitude },
      user: user?.uid ?? '',
      vendor: state?.id ?? '',
      paid: false
    }
    // console.log(data)
    const rid = await captureRequest(data)
    window.location.href = `https://wa.me/${phone}?text=${message(rid)}`
    await toggleRequest(false)
  }

  const handleNew = () => {
    here(false)
    setPhone(state?.phone)
    setIsOpen(3)
  }

  const handleMenu = (id) => {
    setStore(state.title)
    setEnabled(state.active)
    setPhone(state.phone)
    setDesc(state.desc)
    setIsOpen(4)
  }




  return (

    <section className="h-full w-full relative">
      <div className='flex flex-col items-stretch text-gray-900 h-full'>

        <MenuList menuid={menuid} state={state} items={items?.filter((item)=>item.name.toLowerCase().includes(search??''.trim().toLowerCase()))} editor={editor} handleCart={handleCart} cart={cart} user={user} handleNew={handleNew} handleDish={handleDish} locerr={locerr} locsugg={locsugg} handleMenu={handleMenu} isparam={isparam} />

        <Transition appear show={isOpen !== 0} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-0"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-0"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                    {/* <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Update  details
                  </Dialog.Title> */}
                    {isOpen === 1 &&
                      <>
                        <div className="mt-2">
                          <div className='flex flex-col space-y-4'>
                            <Switch.Group>
                              <div className="flex items-center">
                                <Switch.Label className="mr-4">List this Card?</Switch.Label>
                                <Switch
                                  checked={enabled}
                                  onChange={setEnabled}
                                  className={`${enabled ? 'bg-blue-600' : 'bg-gray-200'
                                    } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                                >
                                  <span
                                    className={`${enabled ? 'translate-x-6' : 'translate-x-1'
                                      } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                                  />
                                </Switch>
                              </div>
                            </Switch.Group>
                            <input defaultValue={name} placeholder='Name' type='text' onChange={(e) => handleNameChange(e)}
                              className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />
                            <input defaultValue={desc} placeholder='Description' type='text' onChange={(e) => handleDescChange(e)}
                              className="hidden p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />
                            <input defaultValue={phone} placeholder='Whatsapp Number' type='text' onChange={(e) => handlePhoneChange(e)}
                              className={`p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ${phone === '' ? 'focus-visible:ring-offset-red-300' : 'focus-visible:ring-offset-teal-300'} sm:text-sm`} />
                            <input defaultValue={price} placeholder='Unit Price' type='number' onChange={(e) => handlePriceChange(e)}
                              className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />

                            {parseInt(discount) > -1 && <Switch.Group>
                              <div className="flex items-center">
                                <Switch.Label className="mr-4">Apply discount price</Switch.Label>
                                <Switch
                                  checked={discounted}
                                  onChange={setDiscounted}
                                  className={`${discounted ? 'bg-blue-600' : 'bg-gray-200'
                                    } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                                >
                                  <span
                                    className={`${discounted ? 'translate-x-6' : 'translate-x-1'
                                      } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                                  />
                                </Switch>
                              </div>
                            </Switch.Group>}
                            <input defaultValue={discount} placeholder='Discount Price' type='number' onChange={(e) => handleDiscountChange(e)}
                              className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />
                            <label for="avatar">Update photo:</label>
                            {!file && <img alt='item_photo' src={dish?.image} className='w-16 md:w-24 h-16 md:h-24 object-cover object-center' loading='lazy' />}
                            <input className='bg-white' type="file" accept="image/*" id="avatar" name="avatar"
                              onChange={handleFileChange} />
                          </div>
                        </div>

                        <div className="mt-4">
                          {percent > 0 && percent < 100 ? <span>{percent}% complete..</span>
                            : <button
                              type="button"
                              className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                              onClick={() => handleUpdate(dish)}
                            >
                              Update
                            </button>}
                        </div>
                      </>
                    }

                    {isOpen === -1 &&
                      <>
                        <div className="mb-4">
                          <div className='flex flex-col items-center justify-center py-0'>
                            <img alt='item_photo' src={Logo} className='w-16 h-16 md:w-24 md:h-24 object-cover object-center m-1' loading='lazy' />
                            <span className={`rounded px-4 text-[6px] md:text-xs font-light text-start`}>
                              {dist} away
                            </span>
                            <span className={`rounded px-4 max-w-[500px] mt-8 text-[24px] md:text-xl font-semibold text-start`}>
                              {toTitleCase(name)}
                            </span>
                            <img alt='item_photo' src={dish?.image} className='h-40 rounded md:h-40 object-cover object-center m-4' loading='lazy' />
                            <span className={` rounded px-4 max-w-[500px] text-[30px] md:text-2xl font-bold text-start`}>
                              {discounted ? discount : price}{symbol}
                            </span>

                          </div>
                        </div>
                        <div className='w-full flex flex-row items-center justify-around px-8 pb-16'>
                          <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-6 py-4 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                            onClick={() => removeCart(dish)}
                          >
                            -
                          </button>
                          <span className={`bg-white flex-1 text-center rounded px-4 max-w-[500px] bg-opacity-70 text-[16px] md:text-lg font-bold`}>
                            {cart.length}
                          </span>
                          <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-6 py-4 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                            onClick={() => addCart(dish)}
                          >
                            +
                          </button>
                        </div>

                        <div className="mx-8 mb-4 flex justify-center items-center">
                          {requesting ?
                            <ReactLoading className="loading-anim" type="bubbles" color="green" />
                            :
                            <div className="flex-1 flex flex-row justify-between items-center"><button
                              type="button"
                              className="inline-flex justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-medium text-gray-400 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                              onClick={() => closeModal()}
                            >
                              Dismiss
                            </button>
                              <button
                                disabled={cart.length === 0}
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                onClick={()=>{
                                  console.log(user)
                                  //if(user){handleRequest()}else{signInWithGoogle(handleRequest)}
                                }
                                }
                              >
                                Request
                              </button></div>
                              }

                        </div>
                      </>
                    }

                    {isOpen === 2 &&
                      <>
                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white align-middle shadow-xl transition-all">
                          <Dialog.Title
                            className="leading-6 text-sm text-gray-900 text-center bg-green-100 p-6"
                          > Your clients can only view <b>ACTIVATED</b> cards.<br />An Mpesa prompt will be sent to your phone number when you activate.
                          </Dialog.Title>

                          <div className='flex flex-col items-center justify-center py-8'>
                            <label className=" text-xs">
                              Activate card for <b>{`${period.moment} ${period.key}${period.key === 'month' ? '' : period.key === 'year' ? '' : ''}`}</b></label>
                            <div className='flex flex-row items-center justify-center px-4'>
                              {periods.map((p, idx) => <button key={idx}
                                className={`inline-flex justify-center rounded-md border border-transparent ${p.key === period.key ? 'bg-green-500 text-white' : 'bg-green-100 text-green-900'} px-6 py-4 text-sm font-medium  focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 m-1`}
                                onClick={() => handlePeriod(p, dish)}
                              >
                                {`${p.moment} ${p.key}`}
                              </button>)}
                            </div>
                          </div>

                          <div className='flex flex-col items-center justify-center py-8 px-4'>
                            <span className=' text-lg m-1 font-bold'>
                              {new Intl.NumberFormat().format(charge)}/=
                            </span>

                            <label className="mt-4 mb-1 text-xs" >{"Confirm your number"}</label>
                            <input placeholder='Whatsapp Number'
                              type='tel'
                              onChange={(e) => handleMSISDNChange(e)}
                              name="msisdn"
                              defaultValue={`+${msisdn === '' ? phone : msisdn}`}
                              className={`p-4 text-center font-semibold relative w-full cursor-default overflow-hidden rounded-lg bg-white  shadow-md ring-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ${msisdn === '' ? 'ring-red-100 focus-visible:ring-offset-red-300' : 'ring-teal-100 focus-visible:ring-offset-teal-300'} sm:text-sm`}
                            />
                          </div>

                          <div className="flex items-center justify-around m-4">
                            {(prompting) ?
                              (waiting) ?
                                <ReactLoading className="loading-anim" type="bubbles" color="green" />
                                :
                                <div className="sm:m-4 flex flex-col justify-center items-center">
                                  <span>{`welcome 🙂`}</span>
                                </div>
                              :
                              <>
                                <button onClick={() => { setIsOpen(0) }}
                                  className="font-medium text-gray-600 hover:text-red-400 px-5 py-3 flex items-center transition duration-150 ease-in-out">
                                  Dismiss
                                </button>
                                <button
                                  disabled={!((value?.data()?.["fee"] !== null) && msisdn !== '')}
                                  className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                  onClick={() => {
                                    SendPayload({
                                      amount: charge,
                                      msisdn: msisdn,
                                      account_id: dish?.id
                                    }, { setPrompting, setWaiting })
                                  }}
                                >Activate
                                </button>
                              </>
                            }
                          </div>
                        </Dialog.Panel>
                      </>
                    }
                    {isOpen === 3 &&
                      <>
                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            Fill in all the item's details
                          </Dialog.Title>
                          <div className="mt-2">
                            <div className='flex flex-col space-y-4'>
                              <Switch.Group>
                                <div className="flex items-center">
                                  <Switch.Label className="mr-4">List this Card?</Switch.Label>
                                  <Switch
                                    checked={enabled}
                                    onChange={setEnabled}
                                    className={`${enabled ? 'bg-blue-600' : 'bg-gray-200'
                                      } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                                  >
                                    <span
                                      className={`${enabled ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                                    />
                                  </Switch>
                                </div>
                              </Switch.Group>
                              <input placeholder='Name' type='text' onChange={(e) => handleChange(e, fields[1].name)}
                                className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />
                              <input defaultValue={phone} placeholder='Whatsapp Number' type='text' onChange={(e) => handleChange(e, fields[2].name)}
                                className={`hidden p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ${phone === '' ? 'focus-visible:ring-offset-red-300' : 'focus-visible:ring-offset-teal-300'} sm:text-sm`} />
                              <input placeholder='Description' type='text' onChange={(e) => handleChange(e, fields[3].name)}
                                className="hidden p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />
                              <input placeholder='Unit Price' type='number' onChange={(e) => handleChange(e, fields[4].name)}
                                className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />
                              <label for="avatar">Upload a photo of the item:</label>
                              <input className='bg-white' type="file" accept="image/*" id="avatar" name="avatar"
                                onChange={(e) => handleChange(e, fields[5].name)} />
                            </div>
                          </div>

                          <div className="mt-4">
                            {percent !== 0 ? <span>{percent}% complete..</span>
                              : <button
                                disabled={
                                  name === ''
                                  || parseInt(price) === 0
                                  || price === ''
                                  || phone === ''
                                }
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                onClick={handleUpload}
                              >
                                Submit
                              </button>}
                          </div>
                        </Dialog.Panel>
                      </>
                    }
                    {isOpen === 4 &&
                      <>
                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">

                          <div className="mt-2">
                            <div className='flex flex-col space-y-4'>
                              <Switch.Group>
                                <div className="flex items-center">
                                  <Switch.Label className="mr-4">List this store?</Switch.Label>
                                  <Switch
                                    checked={enabled}
                                    onChange={setEnabled}
                                    className={`${enabled ? 'bg-blue-600' : 'bg-gray-200'
                                      } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                                  >
                                    <span
                                      className={`${enabled ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                                    />
                                  </Switch>
                                </div>
                              </Switch.Group>
                              <Switch.Group>
                                <div className="flex items-center">
                                  <Switch.Label className="mr-4">Use current location?</Switch.Label>
                                  <Switch
                                    checked={locate}
                                    onChange={setLocate}
                                    className={`${locate ? 'bg-blue-600' : 'bg-gray-200'
                                      } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                                  >
                                    <span
                                      className={`${locate ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                                    />
                                  </Switch>
                                </div>
                              </Switch.Group>
                              <input placeholder='Name' type='text' onChange={(e) => handleChange(e, fields[0].name)}
                                defaultValue={state.title}
                                className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />
                                <input placeholder='Service description' type='text' onChange={(e) => handleChange(e, fields[3].name)}
                                  defaultValue={state.desc}
                                  className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />
                              <input placeholder='Whatsapp Number' type='text' onChange={(e) => handleChange(e, fields[2].name)}
                                defaultValue={state.phone}
                                className={`p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ${phone === '' ? 'focus-visible:ring-offset-red-300' : 'focus-visible:ring-offset-teal-300'} sm:text-sm`} />
                              <label for="limit">How far can you serve your client's requests at <b>no additional cost?</b></label>
                              <div className='flex flex-row items-center justify-center px-4 flex-wrap'>
                                {distances.map((p, idx) => <button key={idx}
                                  className={`inline-flex justify-center rounded-md border border-transparent ${p.max === limit ? 'bg-green-500 text-white' : 'bg-green-100 text-green-900'} px-6 py-4 text-sm font-medium  focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 m-1`}
                                  onClick={() => setLimit(p.max)}
                                >
                                  {`${p.max} ${p.key}`}
                                </button>)}
                              </div>

                              <label for="avatar">Update your photo:</label>
                              {!file && <img alt='item_photo' src={state.image} className='w-16 md:w-24 h-16 md:h-24 object-cover object-center' loading='lazy' />}
                              <input className='bg-white' type="file" accept="image/*" id="avatar" name="avatar"
                                onChange={(e) => handleChange(e, fields[5].name)} />
                            </div>
                          </div>

                          <div className="mt-4">
                            {percent !== 0 ? <span>{percent}% complete..</span>
                              : <button
                                disabled={
                                  store === ''
                                  || phone === ''
                                }
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                onClick={handleMenuUpdate}
                              >
                                Submit
                              </button>}
                          </div>
                        </Dialog.Panel>
                      </>
                    }
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>

      </div>
      {!editor && 
        <div className='absolute w-full py-2 px-8 flex bg-white bottom-0 left-0 right-0 justify-between space-x-4 items-center border shadow rounded-b-2xl'>

          <span className='font-bold text-sm'>{isparam?"Subscribe to Order":`Total: ${cart.reduce((acc, item) => {
            const charge = item.discounted ? item.discount : item.price;
            return acc + charge;
          }, 0)
          }/=`}</span>

          {isparam? <button
              className="inline-flex justify-center rounded-md border border-green-900 bg-green-100 px-8 py-2 text-sm font-medium disabled:text-gray-900 disabled:bg-gray-200  text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
              onClick={()=>
                {
                  closePopUp('')
                }
              }
            >
              Okay
            </button>:
          requesting ?
            <ReactLoading className="loading-anim" type="bubbles" color="green" />
            : <button
              className="inline-flex justify-center rounded-md border border-green-900 bg-green-100 px-8 py-2 text-sm font-medium disabled:text-gray-900 disabled:bg-gray-200  text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
              onClick={()=>
                {

                  console.log(user)
                  if(user){handleRequest()}else{signInWithGoogle(handleRequest)}
                }
              }
              disabled={!(cart.length > 0)}
            >
              Send Request
            </button>}
        </div>}
    </section>
  )
}

export default Menu